import React, { Component, Fragment } from 'react';
import Button from '../../util/buttons/components/Button';
import csvToJson from 'csvtojson';
import offeringFromCsvRow from '../util/offeringFromCsvRow';
import './csv-uploader.scss';

class CsvUploader extends Component {
  constructor (props) {
    super(props);
    this.state = {
      csv: null,
      offerings: [],
      parseError: null
    };
    this.fileChangeHandler = this.fileChangeHandler.bind(this);
    this.processCsv = this.processCsv.bind(this);
    this.handleFileRead = this.handleFileRead.bind(this);
    this.fileInputRef = React.createRef();
    /* eslint-disable no-undef */
    this.fileReader = new FileReader();
  }
  handleFileRead () {
    let offerings = [];
    const content = this.fileReader.result;
    let parseError = null;
    let count = 0;
    csvToJson().fromString(content).subscribe(
      jsonObj => {
        count++;
        offerings.push(offeringFromCsvRow(jsonObj));
      },
      error => {
        parseError = error.toString();
        console.log(parseError);
      },
      () => {
        this.setState({ offerings, parseError });
        console.log('added ' + count + ' animals');
        this.fileInputRef.current.value = null;
      }
    );
  }
  fileChangeHandler (event) {
    const file = event.target.files[0];
    this.fileReader = new FileReader();
    this.fileReader.onloadend = this.handleFileRead;
    this.fileReader.readAsText(file);
  }
  processCsv () {

  }
  renderOutput () {
    const cells = this.state.offerings.map(offering => {
      return (
        <div key={offering.id}>
          { JSON.stringify(offering) + ',' }
        </div>
      );
      // return (
      //   <div key={offering.tag}>{ JSON.stringify(offering) + ','}</div>
      // );
    });
    return <div className='results'>[{ cells }]</div>;
  }
  render () {
    return (
      <Fragment>
        <form className='csv-upload-form'>
          <input type='file'
            ref={this.fileInputRef}
            id='csv-upload-file-input'
            accept='.csv'
            onChange={this.fileChangeHandler} />
          <Button label='parse csv' onClick={this.processCsv} />
        </form>
        { this.renderOutput() }
      </Fragment>
    );
  }
}

export default CsvUploader;
