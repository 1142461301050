import React, { Fragment, useState } from 'react';
import './animal-score.scss';
import PropTypes from 'prop-types';
import AnimalScoreSelector from './AnimalScoreSelector';

const AnimalScore = ({ score, factor, update }) => {
  const [ showingOptions, setShowingOptions ] = useState(false);
  const [ startPos, setStartPos ] = useState(window.scrollY);
  const outOf = (factor.key === 'health' ||
    factor.key === 'carbonSequestation' ||
    factor.key === 'nutritionalValue') ? 8 : 4;
  const openSelection = () => {
    setStartPos(window.scrollY);
    setShowingOptions(true);
  };
  const closeSelection = () => {
    setShowingOptions(false);
    window.scrollTo({
      top: startPos,
      behavior: 'auto'
    });
  };
  const select = score => {
    setShowingOptions(false);
    window.scrollTo({
      top: startPos,
      behavior: 'auto'
    });
    update(score);
  };
  const selector = showingOptions ? (
    <AnimalScoreSelector factor={ factor.key }
      pos={ startPos }
      score={ score }
      selectScore={ select }
      close={ closeSelection } />
  ) : null;
  return (
    <Fragment>
      <div onClick={ openSelection }
        data-cy="animal-score-display"
        data-name={ factor.name }
        className="animal-score-selector">
        <div className="field">{ factor.name }</div>
        <div className="score">
          <span className="main">{ score }</span>
          <span className="out-of">{ '/' + outOf }</span>
        </div>
      </div>
      { selector }
    </Fragment>
  );
};

AnimalScore.propTypes = {
  score: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  factor: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
};

export default AnimalScore;
