import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';
import './verification-document.scss';

const VerificationDocument = ({ document, update, deleteDoc, isDragging }) => {
  const [ keyFact, updateKeyFact ] = useState(document.keyFact || '');
  const inputRef = useRef(null);
  const handleChange = () => {
    const value = inputRef.current.value;
    updateKeyFact(value);
  };
  const handleBlur = () => {
    const newDocument = { ...document };
    newDocument.keyFact = keyFact;
    update(newDocument);
  };
  const className = isDragging ? 'verification-document dragging' :
    'verification-document';
  const inputClassName = keyFact.length < 3 ? 'key-fact-input error' :
    'key-fact-input';
  return (
    <div className={ className }
      data-cy={ `verification-document-${document.key}` }>
      <div className="side-bar">
        <div className="position" data-cy={ `doc-position-${document.key}`}>
          { document.position }
        </div>
      </div>
      <div className="doc-and-fact">
        <div className="doc">
          <h5 className="doc-type">
            { document.type }
          </h5>
          <a className="document-link"
            href={ document.src }
            data-cy={ `document-link-${document.key}`}
            target="_blank"
            rel="noopener noreferrer">
            <DownloadIcon />
            <span className="file-name">
              { /[^/]*$/.exec(document.src)[0] }
            </span>
          </a>
        </div>
        <div className="key-fact">
          <input
            className={ inputClassName }
            onChange={ handleChange }
            data-cy={ `key-fact-input-${document.key}` }
            onBlur={ handleBlur }
            ref={ inputRef }
            value={ keyFact} />
        </div>
      </div>
      <div data-cy={ `delete-document-${document.key}` }
        className="delete-symbol"
        onClick={ () => deleteDoc(document.key) }>
        x
      </div>
    </div>
  );
};

VerificationDocument.propTypes = {
  document: PropTypes.shape({
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    keyFact: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired
  }).isRequired,
  update: PropTypes.func.isRequired,
  deleteDoc: PropTypes.func.isRequired,
  isDragging: PropTypes.bool
};

export default VerificationDocument;
