import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getPageOfAdminAnimals,
  getAnimalPage,
  getAnimalPageSize,
  getAnimalCount,
  haveFetchedAnimals
} from '../reducer';
import { fetchAnimals, setAnimalPage } from '../actions';
import PropTypes from 'prop-types';
import './animal-list-view.scss';
import AnimalListViewHeader from './AnimalListViewHeader';
import AdminAnimalListItem from './AdminAnimalListItem';
import { Link } from 'react-router-dom';
import Pagination from '../../util/tables/Pagination';
import AdminSearchBox from '../adminListSearch/components/AdminSearchBox';

const mapStateToProps = state => ({
  animals: getPageOfAdminAnimals(state),
  page: getAnimalPage(state),
  size: getAnimalPageSize(state),
  count: getAnimalCount(state),
  haveFetched: haveFetchedAnimals(state)
});

const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch(fetchAnimals()),
  setPage: page => dispatch(setAnimalPage(page))
});

class AnimalListView extends Component {
  componentDidMount () {
    if (!this.props.haveFetched || this.props.count < 1) {
      this.props.fetch();
    }
  }
  renderSortOptions () {

  }
  renderRows () {
    return this.props.animals.map(animal => {
      return <AdminAnimalListItem animal={ animal }
        key={ animal.id }
        match={ this.props.match } />;
    });
  }
  render () {
    const searchOptions = [
      { name: 'Name', attribute: 'name', dataType: 'string' },
      { name: 'Animal ID', attribute: 'tag', dataType: 'string' },
      { name: 'Organisation', attribute: 'org', dataType: 'string' },
      { name: 'Age (months)', attribute: 'age', dataType: 'number' }
    ];
    return (
      <div id='admin-animal-list'
        data-cy="animal-list-view-section"
        className="bb-page-wrapper animals">
        <Link className="new-animal-button" to={ `${this.props.match.url}/new`}>
          <span className="text">Add new</span>
          <span className="plus-icon">+</span>
        </Link>
        <AdminSearchBox attributes={ searchOptions }/>
        <AnimalListViewHeader />
        { this.renderRows() }
        <Pagination toPage={ this.props.setPage }
          total={ Math.ceil(this.props.count / this.props.size) }
          current={ this.props.page } />
      </div>
    );
  }
}

AnimalListView.propTypes = {
  animals: PropTypes.array,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  haveFetched: PropTypes.bool.isRequired,
  fetch: PropTypes.func,
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  setPage: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimalListView);
