import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './animal-score-selector.scss';
import CloseIcon from '../../util/icons/components/CloseIcon';
import { createPortal } from 'react-dom';
import { getScoreDefinitions } from '../scoreDefinitions/reducer';
import { getSignificance, getScoreCard, getName } from '../scoreDefinitions/util';

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

class AnimalScoreSelector extends Component {
  constructor (props) {
    super(props);
    this.modalRef = React.createRef();
    this.closeRef = React.createRef();
  }
  componentDidMount () {
    if (!this.closeRef || this.closeRef.current === null) return;
    this.closeRef.current.scrollIntoView({ behavior: 'auto' });
  }
  renderScores () {
    const attribute = this.props.factor;
    return getScoreCard(attribute, this.props.scoreDefinitions).map(score => {
      let className = 'score';
      try {
        if (score.score === this.props.score) {
          className += ' active';
        }
      } catch (error) { /* */ }
      let cy = 'animal-score-selector';
      cy += ` ${this.props.scoreDefinitions[attribute].name} ${score.score}`;
      return (
        <li key={score.score}
          className={ className }
          data-cy={ cy }
          onClick={ () => this.props.selectScore(score.score) }>
          <div className='wrapper'>
            <div className='number'>{ score.score }</div>
            <div className='text'>{ score.definition }</div>
          </div>
        </li>
      );
    });
  }
  getContentStyle () {
    if (this.props.pos) {
      return {
        marginTop: `${this.props.pos + 45}px`
      };
    }
    return null;
  }
  render () {
    const attribute = this.props.factor;
    const title = getName(attribute, this.props.scoreDefinitions);
    return createPortal(
      <div id='animal-score-selector'>
        <article
          className='content'
          ref={this.modalRef}
          style={ this.getContentStyle() }>
          <div
            className="close-wrapper"
            ref={this.closeRef}
            onClick={ this.props.close }>
            <CloseIcon/>
          </div>
          <h4 className='mini-title'>what does it mean?</h4>
          <h1 className='title'>{ title }</h1>
          <p className='significance'>
            { getSignificance(attribute, this.props.scoreDefinitions) }
          </p>
          <ul className='scores'>
            { this.renderScores() }
          </ul>
        </article>
      </div>, document.getElementById('app')
    );
  }
}

AnimalScoreSelector.propTypes = {
  factor: PropTypes.string.isRequired,
  score: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  close: PropTypes.func.isRequired,
  selectScore: PropTypes.func.isRequired,
  pos: PropTypes.number,
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps)(AnimalScoreSelector);
