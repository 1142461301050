import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAnimalCount, getAdminSortAttribute } from '../reducer';
import './animal-list-view-header.scss';
import { setAdminAnimalSort } from '../actions';
import DropDownIcon from '../../util/icons/components/DropDownIcon';

const mapStateToProps = state => ({
  animalCount: getAnimalCount(state),
  sort: getAdminSortAttribute(state)
});

const mapDispatchToProps = dispatch => ({
  setSort: (sort) => dispatch(setAdminAnimalSort(sort))
});

const sortOptions = {
  id: 'Creation date',
  name: 'Name',
  gbiScore: 'Score',
  // liveOnBeefBook: 'Showing on BeefBook',
  liveOnGBI: 'Live',
  lastUpdateTime: 'Recently updated'
};

class SearchHeader extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };
    this.wrapperRef = React.createRef();
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setSort = this.setSort.bind(this);
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside (event) {
    if (this.wrapperRef && this.state.open === true &&
        !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  toggleVisibility () {
    this.setState({ open: !this.state.open });
  }

  setSort (option) {
    this.props.setSort(option);
  }

  renderDropDownOptions () {
    return Object.keys(sortOptions).map(key => (
      <li key={ key }
        data-cy={ `animal-sort-option-${key}` }
        onClick={() => this.setSort(key)}>
        { sortOptions[key] }
      </li>
    ));
  }

  render () {
    const optionsClass = this.state.open
      ? 'dropdown-options' : 'dropdown-options hidden';
    const arrowClass = this.state.open ? 'open' : null;
    return (
      <div id='admin-search-header'>
        <div className='count'>
          <span className='number'>
            { this.props.animalCount.toLocaleString() }
          </span>
          Animals
        </div>
        <div ref={this.wrapperRef}
          className='sort'
          data-cy="animal-sort-dropdown"
          onClick={ this.toggleVisibility }>
          { 'Sort by' }
          <p className='attribute'>{ sortOptions[this.props.sort] }</p>
          <ul className={ optionsClass }>
            { this.renderDropDownOptions() }
          </ul>
          <DropDownIcon className={ arrowClass } />
        </div>
        <div className="triangle-wrapper">
          <div className='triangle'></div>
        </div>
      </div>
    );
  }
}

SearchHeader.propTypes = {
  animalCount: PropTypes.number.isRequired,
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);
