/* eslint-disable no-labels */
import mongoid from 'mongoid-js';
import { getOrgIds } from '../../util/jwt';
import documentDefinitions from '../../cows/documentDefinitions';

const validateISODate = str => {
  /* eslint-disable-next-line */
  return /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
    .test(str);
};

export const validateScores = (animal, scoreDefinitions) => {
  const errors = {};
  Object.keys(scoreDefinitions).forEach(key => {
    try {
      const category = scoreDefinitions[key].category;
      let scoreValid = false;
      if (!animal[category] || !animal[category][key] ||
      typeof animal[category][key].score === 'undefined') {
        errors['score_' + key] =
          `A score for ${scoreDefinitions[key].name} is required`;
      }
      scoreDefinitions[key].scoreCard.forEach(def => {
        try {
          if (animal[category][key].score === def.score) scoreValid = true;
        } catch (error) { /* */ }
      });
      if (!scoreValid) {
        errors['score_' + key] =
          `Score for ${scoreDefinitions[key].name} is invalid`;
      }
    } catch (error) {
      errors['score_' + key] =
        `A score for ${scoreDefinitions[key].name} is required`;
    }
  });
  return errors;
};

export const getTypeDependentErrors = animal => {
  const errors = {};
  if (animal.liveOnBeefBook) {
    if (!animal.type) errors.type = 'Required';
    if (!animal.dob) errors.dob = 'Required';
    if (!animal.breed) errors.breed = 'Required';
    if (animal.type === 'FinishedCattle') {
      if (!animal.pricePerKilo) errors.pricePerKilo = 'Required';
      if (!animal.IMF) errors.IMF = 'Required';
      if (!animal.dailyGain) errors.dailyGain = 'Required';
    } else if (animal.type === 'BreedingCows' || animal.type === 'YoungBulls') {
      if (!animal.price) errors.price = 'Required';
      if (!animal.EBV || !animal.EBV.TIscore) errors.EBVTIScore = 'Required';
      if (!animal.EBV || !animal.EBV.SRIscore) errors.EBVSRIScore = 'Required';
      if (!animal.EBV || !animal.EBV.rank) errors.EBVRank = 'Required';
    }
    if (!animal.weight) {
      errors.weight = 'Required';
    }
    if (!animal.weightDate) {
      errors.weightDate = 'Required';
    }
  }
  return errors;
};

const getDocumentErrors = animal => {
  const errors = {};
  if (animal && animal.documents) {
    const keys = Object.keys(animal.documents);
    let errStr = '';
    keys.forEach(key => {
      if (documentDefinitions[key]) {
        const doc = animal.documents[key];
        if (typeof doc.src !== 'string') {
          if (errStr) {
            errStr += `, ${key} src attribute missing`;
          } else {
            errStr = `${key} src attribute missing`;
          }
        } else {
          try {
            // eslint-disable-next-line no-new
            new URL(doc.src);
          } catch (error) {
            if (errStr) {
              errStr += `, ${key} src attribute is an invalid URL`;
            } else {
              errStr = `${key} src attribute is an invalid URL`;
            }
          }
        }
        if (doc.keyFact && typeof doc.keyFact !== 'string') {
          if (errStr) {
            errStr += `, ${key} Key Fact must be a string`;
          } else {
            errStr = `${key} Key Fact must be a string`;
          }
        } else if (doc.keyFact && doc.keyFact.length > 44) {
          if (errStr) {
            errStr += `, ${key} Key Fact must be less than 44 characters`;
          } else {
            errStr = `${key} Key Fact must be less than 44 characters`;
          }
        } else if (typeof doc.keyFact === 'string' && doc.keyFact.length < 3) {
          errStr += `${key} key Fact must contain at least 2 characters`;
        }
      }
    });
    if (errStr) {
      errors.documents = errStr;
    }
  }
  return errors;
};

export const getAnimalValidationErrors = (
  animalToValidate, scoreDefinitions
) => {
  let errors = {};
  const animal = animalToValidate || {};
  if (!animal.tag) {
    errors.tag = 'Required';
  } else if (typeof animal.tag !== 'string' || animal.tag.length < 3) {
    errors.tag = 'Must be a string at least 3 characters long';
  }

  errors = {
    ...errors,
    ...validateScores(animal, scoreDefinitions),
    ...getDocumentErrors(animal),
    ...getTypeDependentErrors(animal)
  };

  if (animal.EBV) {
    if (animal.EBV.TIscore && typeof animal.EBV.TIscore !== 'number') {
      errors.EBVTIscore = 'Must be a number';
    }
    if (animal.EBV.SRIscore && typeof animal.EBV.SRIscore !== 'number') {
      errors.EBVSRIscore = 'Must be a number';
    }
    if (animal.EBV.rank && typeof animal.EBV.rank !== 'string') {
      errors.EBVRank = 'Must be a string';
    } else if (animal.EBV.rank && !/^[1-9]?[0-9]%$/.test(animal.EBV.rank)) {
      errors.EBVRank = 'Must be an percentage between 0-100%';
    }
  }
  if (animal.dob && !validateISODate(animal.dob)) {
    errors.dob = 'Must be a valid date';
  }
  return errors;
};

export const newAnimal = (org, scoreDefinitions) => {
  const production = {};
  const eating = {};
  const environment = {};
  Object.keys(scoreDefinitions).forEach(key => {
    switch (scoreDefinitions[key].category) {
      case 'production':
        production[key] = {}; break;
      case 'eating':
        eating[key] = {}; break;
      case 'environment':
        environment[key] = {}; break;
    }
  });
  const id = mongoid();
  const animal = { id, production, eating, environment };
  if (org) {
    if (org.scores) {
      Object.keys(org.scores).forEach(scoreKey => {
        try {
          const scoreDefinition = scoreDefinitions[scoreKey];
          animal[scoreDefinition.category][scoreKey] =
            animal[scoreDefinition.category][scoreKey] || {};
          animal[scoreDefinition.category][scoreKey].score =
            org.scores[scoreKey];
        } catch (error) { /* */ }
      });
    }
    if (org.images && Array.isArray(org.images) && org.images.length > 1) {
      animal.orgImage = org.images[0];
    }
    if (org.id) {
      animal.orgId = org.id;
    }
  } else {
    const orgIds = getOrgIds();
    if (orgIds && orgIds.length > 0) {
      animal.orgId = orgIds[0];
    }
  }
  return animal;
};

export const updateAnimalWithNewOrg = (org, animal, scoreDefinitions) => {
  if (org) {
    if (org.scores) {
      Object.keys(org.scores).forEach(scoreKey => {
        Object.keys(org.scores).forEach(scoreKey => {
          const scoreDefinition = scoreDefinitions[scoreKey];
          animal[scoreDefinition.category][scoreKey] =
            animal[scoreDefinition.category][scoreKey] || {};
          if (typeof animal[scoreDefinition.category][scoreKey].score ===
          'undefined') {
            animal[scoreDefinition.category][scoreKey].score =
              org.scores[scoreKey];
          }
        });
      });
    }
    if (org.images && Array.isArray(org.images) && org.images.length > 1) {
      animal.orgImage = org.images[0];
    }
    if (org.id) {
      animal.orgId = org.id;
    }
  }
};

const animalHasDocuments = documents => {
  if (documents) {
    const keys = Object.keys(documentDefinitions);
    for (let i = 0; i < keys.length; i++) {
      try {
        if (documents[keys[i]].src.length > 0) return true;
      } catch (error) { /* */ }
    }
  }
  return false;
};

const animalHasEBVs = EBV => {
  if (!EBV) return false;
  if (EBV.TIscore || EBV.SRIscore || EBV.rank) return true;
};

export const newAnimalHasChanged = (animal, org = null, scoreDefinitions) => {
  const keys = Object.keys(scoreDefinitions);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const category = scoreDefinitions[key].category;
    if (animal[category] && animal[category][key] &&
      typeof animal[category][key].score === 'number') {
      if (org && org.scores && typeof org.scores[key] === 'number') {
        if (org.scores[key] !== animal[category][key].score) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  if (
    animal.tag || animal.type || animal.breed || animal.location ||
    animal.price || animal.pricePerKilo || animal.dob || animal.birthweight ||
    animal.dailyGain || animal.liveOnBeefBook || animal.liveOnGBI ||
    animal.name || animal.IMF
  ) return true;
  if (animalHasDocuments(animal.documents)) return true;
  if (animalHasEBVs(animal.EBV)) return true;
  return false;
};

export const animalAttributes = [
  'id', 'orgId', 'name', 'tag', 'type', 'weight', 'weightDate', 'birthWeight',
  'dailyGain', 'breed',
  'location', 'price', 'pricePerKilo', 'dob',
  'IMF', 'calfSire', 'calfDue', 'sold', 'availableDate',
  'liveOnBeefBook', 'liveOnGBI', 'availabilityStatus'
];

export const scoresHaveChanged = (animal1, animal2, scoreDefinitions) => {
  const keys = Object.keys(scoreDefinitions);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const score = scoreDefinitions[key];
    const category = score.category;
    if (animal1[category]) {
      if (!animal2[category]) {
        return true;
      }
    }
    if (animal2[category]) {
      if (!animal1[category]) {
        return true;
      }
    }
    if (animal1[category] && animal2[category]) {
      const scores1 = animal1[category];
      const scores2 = animal2[category];
      if (scores1[key] && !scores2[key]) {
        return true;
      }
      if (scores2[key] && !scores1[key]) {
        return true;
      }
      if (scores1[key] && scores2[key]) {
        if (scores1[key].score !== scores2[key].score) {
          return true;
        }
      }
    }
  }
  return false;
};

export const ebvsChanged = (animal1, animal2) => {
  if (!!animal1.EBV !== !!animal2.EBV) return true;
  if (animal1.EBV) {
    if (animal1.EBV.TIscore !== animal2.EBV.TIscore) {
      return true;
    }
    if (animal1.EBV.SRIscore !== animal2.EBV.SRIscore) {
      return true;
    }
    if (animal1.EBV.rank !== animal2.EBV.rank) {
      return true;
    }
    if (animal1.EBV.url !== animal2.EBV.url) {
      return true;
    }
  }
  return false;
};

export const documentsHaveChanged = (animal1, animal2) => {
  if (!!animal1.documents !== !!animal2.documents) return true;
  if (!animal1.documents) return false;
  if (typeof animal1.documents !== typeof animal2.documents) return true;
  if (typeof animal1.documents !== 'object') return false;
  const keys = Object.keys(animal1.documents);
  if (keys.length !== Object.keys(animal2.documents).length) return true;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (typeof animal1.documents[key] !== typeof animal2.documents[key]) {
      return true;
    }
    if (typeof animal1.documents[key] === 'object') {
      if (animal1.documents[key].src !== animal2.documents[key].src) {
        return true;
      }
      if (animal1.documents[key].keyFact !== animal2.documents[key].keyFact) {
        return true;
      }
    }
  }
  return false;
};

export const imagesHaveChanged = (animal1, animal2) => {
  if (!!animal1.orgImage !== !!animal2.orgImage) {
    return true;
  } else if (animal1.orgImage) {
    if (animal1.orgImage.base !== animal2.orgImage.base) {
      return true;
    }
  }
  if (!!animal1.images !== !!animal2.images) return true;
  if (!animal1.images || !Array.isArray(animal1.images)) {
    if (Array.isArray(animal2.images)) {
      return true;
    }
    return false;
  }
  loop1: for (let i = 0; i < animal1.images.length; i++) {
    const img = animal1.images[i];
    for (let j = 0; j < animal2.images.length; j++) {
      const img2 = animal2.images[j];
      if (img2.base === img.base) continue loop1;
    }
    return true;
  }
  return false;
};

export const animalHasChanged = (animal1, animal2, scoreDefinitions) => {
  if (!animal1 && !animal2) return false;
  if (typeof animal1 === 'undefined') {
    if (typeof animal2 === 'undefined') return false;
    return true;
  }
  if (typeof animal2 === 'undefined') return true;
  for (let i = 0; i < animalAttributes.length; i++) {
    const attribute = animalAttributes[i];
    if (animal1[attribute] !== animal2[attribute]) {
      return true;
    }
  }
  if (scoresHaveChanged(animal1, animal2, scoreDefinitions)) return true;
  if (ebvsChanged(animal1, animal2)) return true;
  if (documentsHaveChanged(animal1, animal2)) return true;
  if (imagesHaveChanged(animal1, animal2)) return true;
  return false;
};
