import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AnimalQRCodeLabelsPDF from './AnimalQRCodeLabelsPDF';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import getCreationTimeFromMongoId from '../../util/getCreationTimeFromMongoId';
import { Link } from 'react-router-dom';
import './animal-qr-section.scss';
import getDataURIFromImageSrc from '../util/getDataURIFromImageSrc';

const tickSrc = 'https://media.goodbeefindex.org/pdf/gbitick.png';
const logoSrc = 'https://media.goodbeefindex.org/pdf/gbilogo.png';

const AnimalQRSection = ({ animal }) => {
  if (!animal || !animal.tag || !animal.saved) return null;
  const [tickDataUri, setTickDataUri] = useState(null);
  const [logoDataUri, setLogoDataUri] = useState(null);
  const [showQR, setShowQR] = useState(false);
  useEffect(() => {
    setShowQR(true);
    return () => setShowQR(false);
  }, []);
  const qrElementId = `qr-code-${animal.id}`;
  const downloadClicked = () => {
    const canvas = document.getElementById(qrElementId);
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = '123456.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  getDataURIFromImageSrc(tickSrc, setTickDataUri, 'png');
  getDataURIFromImageSrc(logoSrc, setLogoDataUri, 'png');
  if (!animal) return null;
  const name = animal.name || null;
  const tag = animal.tag || '';
  const domain = window.location.host;
  const protocol = window.location.protocol;
  const url = `${protocol}//${domain}/animal/${animal.id}`;
  const props = { name, tag, tickDataUri, logoDataUri };
  let fileName = `${tag.replace(' ', '_')}_labels.pdf`;
  const creationString = getCreationTimeFromMongoId(animal.id);
  const labelsLink = tickDataUri && logoDataUri && showQR ? (
    <PDFDownloadLink
      document={<AnimalQRCodeLabelsPDF { ...props } />}
      fileName={ fileName }>
        Download qr code labels
    </PDFDownloadLink>
  ) : null;
  const pngDownloadLink = (
    <p className="png-download" onClick={ downloadClicked }>
      download a qr code (png)
    </p>
  );
  return (
    <div className='animal-detail-qr-section'>
      <div id="animal-qr-code-wrapper">
        <QRCode size={ 140 }
          value={ url }
          renderAs='canvas'
          id={ qrElementId }/>
      </div>
      <div>
        <h3 className="title">QR Code</h3>
        <p className="creation-string">{ creationString }</p>
        <p className="url">
          <span className="bold">url: </span>
          { url }
        </p>
        { labelsLink }
        { pngDownloadLink }
        <Link to={ `/animal/${animal.id}` } data-cy="qr-page-link">
          View the public QR code page
        </Link>
      </div>
    </div>
  );
};

AnimalQRSection.propTypes = {
  animal: PropTypes.object
};

export default AnimalQRSection;
