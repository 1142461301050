import React from 'react';
import PropTypes from 'prop-types';
import DropDownSelectField from '../../form/DropDownSelectField';

const FarmSelectionDropDown = ({ farms, setFarm, farmId }) => {
  const options = Object.keys(farms).map(id => ({
    name: farms[id].name, value: id, selected: farmId === id
  }));
  if (options.length < 2) return null;
  return (
    <DropDownSelectField name="Farm"
      options={ options }
      update={ setFarm } />
  );
};

FarmSelectionDropDown.propTypes = {
  farms: PropTypes.object.isRequired,
  setFarm: PropTypes.func.isRequired,
  farmId: PropTypes.string
};

export default FarmSelectionDropDown;
