import React from 'react';
import PropTypes from 'prop-types';
import './progress-bar.scss';

const ProgressBar = ({ percentage }) => {
  const styles = {
    transform: `scaleX(${percentage / 100})`,
    transformOrigin: 'left'
  };
  return (
    <div className='progress-bar-container'>
      <div style={ styles } className="mercury"></div>
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number
};

export default ProgressBar;
