/* eslint-disable max-len */
import React from 'react';

const CalendarIcon = () => (
  <svg className="calendar-icon" viewBox="0 0 98 91.34">
    <path d="M79.43,12V1H71.35V12H54V1H46V12H28.65V1H20.57V12H1V78.58A13.81,13.81,0,0,0,14.79,92.37H85.21A13.81,13.81,0,0,0,99,78.58V12ZM90.92,78.58a5.71,5.71,0,0,1-5.71,5.71H14.79a5.71,5.71,0,0,1-5.71-5.71V20.05H20.57v5.81h8.08V20.05H46v5.81H54V20.05H71.35v5.81h8.08V20.05H90.92Z" transform="translate(-1 -1.02)"/>
    <rect x="18.99" y="33.97" width="14.37" height="14.37"/>
    <rect x="42.08" y="33.97" width="14.37" height="14.37"/>
    <rect x="65.16" y="33.97" width="14.37" height="14.37"/>
    <rect x="18.99" y="57.05" width="14.37" height="14.37"/>
    <rect x="42.08" y="57.05" width="14.37" height="14.37"/>
    <rect x="65.16" y="57.05" width="14.37" height="14.37"/>
  </svg>
);

export default CalendarIcon;
