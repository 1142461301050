import moment from 'moment';

const getCreationTimeFromMongoId = id => {
  if (!id) return '';
  const timestamp = id.toString().substring(0, 8);
  let creationDate = new Date(parseInt(timestamp, 16) * 1000);
  return `Generated ${moment(creationDate).fromNow()}`;
};

export default getCreationTimeFromMongoId;
