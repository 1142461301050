import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getAdminAnimalsObject,
  isFetching,
  getAnimalsFetching } from '../reducer';
import {
  updateAnimal,
  deleteAnimal,
  fetchAnimal,
  addAnimal } from '../actions';
import PropTypes from 'prop-types';
import TextField from '../../form/TextField';
import {
  getAnimalValidationErrors,
  newAnimal,
  updateAnimalWithNewOrg,
  newAnimalHasChanged,
  animalHasChanged
} from '../validation/animal';
import './animal.scss';
import LoadingIcon from '../../util/icons/components/LoadingIcon';
import NumberField from '../../form/NumberField';
import DropDownSelectField from '../../form/DropDownSelectField';
import { getCowTypeDropDownOptions } from '../../cows/util/cowTypes';
import { breeds } from '../../cowFilters/components/BreedFilter';
import DateField from '../../form/DateField';
import DocumentUpload from '../documents/components/DocumentUpload';
// import documentDefinitions from '../../cows/documentDefinitions';
import AdminAPI from '../AdminAPI';
import { Prompt, Link } from 'react-router-dom';
import ChevronIcon from '../../util/icons/components/ChevronIcon';
import AnimalScoreDisplay from './AnimalScoreDisplay';
import AnimalScore from './AnimalScore';
import moment from 'moment';
import TickBoxField from '../../form/TickBoxField';
import { canPostOnBB, canPostOnGBI, isAdmin } from '../../util/jwt';
import getS3ObjectFromURL from '../util/getS3ObjectFromURL';
import { getOrgsObj } from '../orgAdmin/reducer';
import { fetchOrgs } from '../orgAdmin/actions';
// import Images from '../imageAdmin/components/Images';
import FarmSelectionDropDown from './FarmSelectionDropDown';
import { captureException } from '../../util/logger';
import AnimalQRSection from '../qrcode/components/AnimalQRSection';
import ErrorBoundary from '../../util/components/ErrorBoundary';
import { getScoreDefinitions } from '../scoreDefinitions/reducer';
import FarmDocumentSelector from '../documents/components/FarmDocumentSelector';
import DocumentList from '../documents/components/DocumentList';
import Modal from '../../main/components/Modal';
import GBIButton from '../../util/buttons/components/GBIButton';
import analytics from '../../util/analytics';
import ExistingVideo from '../videoAdmin/components/ExistingVideo';
// import VideoAdminSection from '../videoAdmin/components/VideoAdminSection';

const mapStateToProps = state => ({
  animals: getAdminAnimalsObject(state),
  orgs: getOrgsObj(state),
  loadingAll: isFetching(state),
  individualsLoading: getAnimalsFetching(state),
  scoreDefinitions: getScoreDefinitions(state)
});

const mapDispatchToProps = dispatch => ({
  add: (animal, files, redirectUrl) =>
    dispatch(addAnimal(animal, files, redirectUrl)),
  update: (animal, files) => dispatch(updateAnimal(animal, files)),
  delete: (animal, files) => dispatch(deleteAnimal(animal, files)),
  fetch: id => dispatch(fetchAnimal(id)),
  fetchOrgs: orgId => dispatch(fetchOrgs(orgId))
});

class Animal extends Component {
  constructor (props) {
    super(props);
    let animal = props.animals[props.match.params.animalId] || null;
    let errors = {};
    let org = null;
    try {
      let orgId = Object.keys(this.props.orgs)[0];
      org = this.props.orgs[orgId];
    } catch (error) { /* */ }
    if (props.match.params.animalId === 'new') {
      animal = newAnimal(org, this.props.scoreDefinitions);
    }
    errors = getAnimalValidationErrors(animal, props.scoreDefinitions);
    this.state = {
      ignorePromptOnRouteChange: false,
      animal: animal,
      urlAnimalId: props.match.params.animalId,
      errors,
      showErrors: false,
      showDelete: false,
      tab: 'production',
      filesForDeletion: [], // files aren't removed from s3 until changes are saved to db.
      newFiles: [] // files will be removed from s3 if changes aren't saved.
    };
    this.updateFormField = this.updateFormField.bind(this);
    if (this.props.match.params.animalId &&
        this.props.match.params.animalId !== 'new') {
      props.fetch(this.props.match.params.animalId);
    }
    this.props.fetchOrgs();
  }
  static getDerivedStateFromProps (props, state) {
    let ignorePromptOnRouteChange = state.ignorePromptOnRouteChange;
    let urlAnimalId = state.urlAnimalId;
    if (props.match.params.animalId !== state.urlAnimalId) {
      ignorePromptOnRouteChange = false;
      urlAnimalId = props.match.params.animalId;
    }
    if (state.animal === null && props.match.params.animalId !== 'new' &&
      props.animals[props.match.params.animalId]) {
      const animal = props.animals[props.match.params.animalId];
      const errors =
        getAnimalValidationErrors(animal, props.scoreDefinitions);
      return {
        ...state, animal, errors, ignorePromptOnRouteChange, urlAnimalId
      };
    }

    return { ...state, ignorePromptOnRouteChange, urlAnimalId };
  }
  componentDidMount () {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
  componentWillUnmount () {
    if (this.state.newFiles && this.state.newFiles.length) {
      AdminAPI.deleteDocuments(this.state.newFiles).catch(
        error => captureException(error)
      );
    }
  }
  isLoading () {
    return this.props.loadingAll ||
    this.props.individualsLoading[this.props.match.params.animalId];
  }
  hasErrors () {
    return Object.values(this.state.errors).length > 0;
  }
  setOrg (id) {
    const animal = JSON.parse(JSON.stringify(this.state.animal)) || {};
    if (this.props.orgs && this.props.orgs[id]) {
      updateAnimalWithNewOrg(
        this.props.orgs[id], animal, this.props.scoreDefinitions
      );
    } else {
      animal.orgId = id;
    }
    this.setState({
      animal,
      errors: getAnimalValidationErrors(animal, this.props.scoreDefinitions)
    });
  }
  deleteAttribute (fieldName) {
    const animal = JSON.parse(JSON.stringify(this.state.animal)) || {};
    delete animal[fieldName];
    this.setState({
      animal,
      errors: getAnimalValidationErrors(animal, this.props.scoreDefinitions)
    });
  }
  updateFormField (fieldName, fieldValue) {
    const animal = JSON.parse(JSON.stringify(this.state.animal)) || {};
    animal[fieldName] = fieldValue;
    this.setState({
      animal,
      errors: getAnimalValidationErrors(animal, this.props.scoreDefinitions)
    });
  }
  updateEBVField (fieldName, fieldValue) {
    const animal = JSON.parse(JSON.stringify(this.state.animal)) || {};
    animal.EBV = animal.EBV || {};
    animal.EBV[fieldName] = fieldValue;
    this.setState({
      animal,
      errors: getAnimalValidationErrors(animal, this.props.scoreDefinitions)
    });
  }
  updateGBIScore (element, factor, score) {
    const animal = JSON.parse(JSON.stringify(this.state.animal)) || {};
    const scores = this.props.scoreDefinitions[factor].scoreCard;
    let validScore = false;
    for (let i = 0; i < scores.length; i++) {
      if (scores[i].score === score) {
        validScore = true;
        break;
      }
    }
    if (validScore) {
      animal[element] = animal[element] || {};
      animal[element][factor] = animal[element][factor] || {};
      animal[element][factor].score = score;
    }
    this.setState({
      animal,
      errors: getAnimalValidationErrors(animal, this.props.scoreDefinitions)
    });
  }
  handleIMFUpdate (value) {
    const animal = JSON.parse(JSON.stringify(this.state.animal)) || {};
    animal.IMF = value;
    let score = 0;
    if (value >= 6) score = 4;
    else if (value >= 4) score = 3;
    else if (value >= 2) score = 2;
    else if (value >= 1) score = 1;
    const factor = 'flavour';
    const element = 'eating';
    try {
      animal[element] = animal[element] || {};
      animal[element][factor] = animal[element][factor] || {};
      animal[element][factor].score = score;
    } catch (error) { /* */ }
    this.setState({
      animal,
      errors: getAnimalValidationErrors(animal, this.props.scoreDefinitions)
    });
  }
  handleSubmit (event) {
    event.preventDefault();
  }
  handleSaveClicked () {
    const animal = this.props.animals[this.props.match.params.animalId];
    if (this.hasErrors()) {
      this.setState({ showErrors: true });
    }
    if (this.hasErrors() || !this.unsavedChanges()) return;
    if (!animal || !animal.saved) {
      let redirectUrl = null;
      if (this.props.match.params.animalId === 'new') {
        redirectUrl = this.props.match.url.replace('new', this.state.animal.id);
        this.setState({ ignorePromptOnRouteChange: true });
      }
      this.props.add(
        this.state.animal, this.state.filesForDeletion, redirectUrl
      );
      analytics(
        'send',
        'event',
        'AnimalUploads',
        'newAnimalSaved',
        this.state.animal.tag
      );
    } else {
      this.props.update(this.state.animal, this.state.filesForDeletion);
    }
    this.setState({ filesForDeletion: [], newFiles: [] });
  }
  handleDeleteClicked () {
    this.props.delete(this.state.animal, this.state.newFiles);
    this.props.history.push('/account/animals');
  }
  getAnimalAttribute (...attributePath) {
    try {
      let attribute = this.state.animal;
      attributePath.forEach(attributeName => {
        attribute = attribute[attributeName];
      });
      return attribute;
    } catch (error) {
      return null;
    }
  }
  getTypeOptions () {
    return getCowTypeDropDownOptions(this.getAnimalAttribute('type'))
      .filter(option => {
        return ['YoungBulls', 'BreedingCows', 'FinishedCattle']
          .includes(option.value);
      });
  }

  addDocumentRef (file, documentType, keyFact) {
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    animal.documents = animal.documents || {};
    const filesForDeletion =
      JSON.parse(JSON.stringify(this.state.filesForDeletion));
    if (animal.documents[documentType]) {
      if (!this.isFarmDocument(file, documentType)) {
        const s3Object = getS3ObjectFromURL(file);
        filesForDeletion.push(s3Object);
      }
    }
    animal.documents[documentType] = animal.documents[documentType] || {};
    animal.documents[documentType].src = file;
    if (keyFact) {
      animal.documents[documentType].keyFact = keyFact;
    }
    const s3Object = getS3ObjectFromURL(file);
    const newFiles =
      JSON.parse(JSON.stringify(this.state.newFiles));
    newFiles.push(s3Object);
    this.setState({ animal, newFiles, filesForDeletion });
    analytics(
      'send',
      'event',
      'AnimalUploads',
      'animalDocumentUploaded',
      animal.tag
    );
  }
  removeDocument (file) {
    const s3Object = getS3ObjectFromURL(file);
    const newFiles =
      JSON.parse(JSON.stringify(this.state.newFiles));
    newFiles.push(s3Object);
    this.setState({ newFiles });
  }
  addImage (src) {
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    const images = animal.images || [];
    const imageData = {
      fileName: src.substring(src.lastIndexOf('/') + 1),
      processed: false,
      base: src
    };
    images.push(imageData);
    animal.images = images;
    const s3Object = getS3ObjectFromURL(src);
    const newFiles =
      JSON.parse(JSON.stringify(this.state.newFiles));
    newFiles.push(s3Object);
    this.setState({ animal, newFiles });
  }
  deleteImage (imageData) {
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    let images = animal.images || [];
    images = images.filter(img => {
      return img.base !== imageData.base;
    });
    animal.images = images;
    const filesForDeletion = [ ...this.state.filesForDeletion ];
    ['base', 'thumb330', 'thumb660', 'large1080', 'large2160'].forEach(attr => {
      if (imageData[attr]) {
        filesForDeletion.push(imageData[attr]);
      }
    });
    this.setState({ animal, filesForDeletion });
  }
  moveImage (oldIndex, newIndex) {
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    let images = animal.images;
    const image = images[oldIndex];
    images.splice(oldIndex, 1);
    images.splice(newIndex, 0, image);
    animal.images = images;
    this.setState({ animal });
  }
  isFarmDocument (src, key) {
    const animal = this.state.animal;
    if (!animal.orgId) return false;
    if (!this.props.orgs[animal.orgId]) return false;
    if (!this.props.orgs[animal.orgId].documents) return false;
    if (!this.props.orgs[animal.orgId].documents[key]) return false;
    if (!this.props.orgs[animal.orgId].documents[key].src) return false;
    return this.props.orgs[animal.orgId].documents[key].src === src;
  }
  deleteDocumentRef (key) {
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    const src = animal.documents[key].src;
    delete animal.documents[key];
    const filesForDeletion =
      JSON.parse(JSON.stringify(this.state.filesForDeletion));
    if (!this.isFarmDocument(src, key)) {
      const s3Object = getS3ObjectFromURL(src);
      filesForDeletion.push(s3Object);
    }
    this.setState({ animal, filesForDeletion });
  }
  addFarmDocument (key, src, keyFact) {
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    animal.documents = animal.documents || {};
    const newFiles =
      JSON.parse(JSON.stringify(this.state.newFiles));
    if (animal.documents[key] && animal.documents[key].src &&
    animal.documents[key].src !== src) {
      const s3Object = getS3ObjectFromURL(animal.documents[key].src);
      newFiles.push(s3Object);
    }
    animal.documents[key] = { src };
    if (keyFact) {
      animal.documents[key].keyFact = keyFact;
    }
    this.setState({ animal, newFiles });
  }
  updateDocuments (documents) {
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    animal.documents = documents;
    const errors =
      getAnimalValidationErrors(animal, this.props.scoreDefinitions);
    this.setState({ animal, errors });
  }
  getError (errorKey) {
    if (this.state.showErrors && this.state.errors[errorKey]) {
      return this.state.errors[errorKey];
    }
    return null;
  }
  unsavedChanges () {
    if (this.props.match.params.animalId === 'new') {
      let org = null;
      if (this.props.orgs && this.state.animal.orgId &&
        this.props.orgs[this.state.animal.orgId]) {
        org = this.props.orgs[this.state.animal.orgId];
      }
      return newAnimalHasChanged(
        this.state.animal, org, this.props.scoreDefinitions
      );
    }
    const savedAnimal = this.props.animals[this.props.match.params.animalId];
    const animal = JSON.parse(JSON.stringify(this.state.animal));
    return animalHasChanged(savedAnimal, animal, this.props.scoreDefinitions);
  }
  renderPrice () {
    if (this.state.animal) {
      const attribute = this.state.animal.type === 'FinishedCattle' ?
        'pricePerKilo' : 'price';
      const title = this.state.animal.type === 'FinishedCattle' ?
        'Price Per Kilo (£)' : 'Price (£)';
      const update = pounds => {
        pounds = pounds || 0;
        const pence = Math.round(pounds * 100);
        this.updateFormField(attribute, pence);
      };
      const value = this.getAnimalAttribute(attribute) ?
        this.getAnimalAttribute(attribute) / 100 : null;
      return (
        <NumberField name={ title }
          value={ value }
          testId="animal-price"
          error={ this.getError(attribute) }
          update={ update } />
      );
    }
    return null;
  }
  renderBirthDate () {
    const current = this.getAnimalAttribute('dob');
    const value = current ? new Date(current) : undefined;
    return (
      <DateField name="Birth Date"
        value={ value }
        testId="animal-dob-field"
        error={ this.getError('dob') }
        update={ value => this.updateFormField('dob', value) } />
    );
  }
  renderAvailabilityStatus () {
    if (!this.state.animal ||
      this.state.animal.liveOnBeefBook !== true) return null;
    const current = this.getAnimalAttribute('availabilityStatus');
    const options = [
      {
        name: 'available',
        value: 'AVAILABLE',
        selected: current === 'AVAILABLE'
      },
      { name: 'reserved', value: 'RESERVED', selected: current === 'RESERVED' },
      { name: 'sold', value: 'SOLD', selected: current === 'SOLD' }
    ];
    const update = value => {
      this.updateFormField('availabilityStatus', value);
    };
    return (
      <DropDownSelectField
        options={ options }
        name="Status"
        error={ this.getError('availabilityStatus') }
        update={ update } />
    );
  }
  renderAvailableDate () {
    if (!this.state.animal ||
      this.state.animal.liveOnBeefBook !== true) return null;
    const status = this.getAnimalAttribute('availabilityStatus');
    if (!status || status !== 'AVAILABLE') return null;
    const current = this.getAnimalAttribute('availableDate');
    const value = current ? new Date(current) : undefined;
    const clear = () => this.deleteAttribute('availableDate');
    const toggle = () =>
      this.updateFormField('availableDate', new Date().toISOString());
    if (!current) {
      return (
        <TickBoxField
          title="Available Immediately"
          isOn={ true }
          error={ this.getError('availableDate') }
          toggle={ toggle } />
      );
    }
    return (
      <DateField name="Availability Date"
        value={ value }
        error={ this.getError('availableDate') }
        placeholder="available now"
        update={ value => this.updateFormField('availableDate', value) } >
        <span onClick={ clear } className="clear-date-icon">x</span>
      </DateField>
    );
  }
  renderLiveOnBeefBookField () {
    if (!canPostOnBB() && !isAdmin()) return null;
    const isOn = this.getAnimalAttribute('liveOnBeefBook') || false;
    return (
      <TickBoxField
        title="Carcass Sales"
        testId="display-on-bb-tick-box"
        isOn={ isOn }
        error={ this.getError('liveOnBeefBook') }
        toggle={ () => this.updateFormField('liveOnBeefBook', !isOn)} />
    );
  }
  renderLiveOnGBIField () {
    if (!canPostOnGBI()) return null;
    const isOn = this.getAnimalAttribute('liveOnGBI') || false;
    return (
      <TickBoxField
        title="Beef Sales"
        testId="display-on-gbi-tick-box"
        isOn={ isOn }
        error={ this.getError('liveOnGBI') }
        toggle={ () => this.updateFormField('liveOnGBI', !isOn)} />
    );
  }
  renderDeleteModal () {
    if (!this.state.showDelete) return null;
    let title = 'new animal';
    if (this.state.animal && (
      this.state.animal.name || this.state.animal.tag
    )) {
      title = this.state.animal.name || this.state.animal.tag;
    }
    return (
      <Modal
        pos={ window.scrollY }
        contentClass="delete-animal-modal-content"
        close={ () => this.setState({ showDelete: false })} >
        <div>
          <h2 className="title">{ `Delete ${title}?` }</h2>
          <p className="text">{ 'this action can not be undone' }</p>
          <div className="button-row">
            <GBIButton
              onClick={ () => this.setState({ showDelete: false }) }
              label="cancel"
              className="light" />
            <GBIButton onClick={ this.handleDeleteClicked.bind(this) }
              testId="animal-confirm-delete-button"
              label="delete" />
          </div>
        </div>
      </Modal>
    );
  }
  renderButtons () {
    let saveButtonClass = 'button';
    if ((this.hasErrors() && this.state.showErrors) ||
      !this.unsavedChanges()) {
      saveButtonClass += ' disabled';
    } else {
      saveButtonClass += ' save';
    }
    const animal = this.props.animals[this.props.match.params.animalId];
    let deleteButtonClass = 'button';
    if (animal && animal.saved) {
      deleteButtonClass += ' delete';
    } else {
      deleteButtonClass += ' disabled';
    }
    const error = this.state.showErrors &&
      Object.keys(this.state.errors).length ? (
        <div data-cy="animal-error-message" className="top-level-error-message">
          Please correct the errors marked above
        </div>
      ) : null;
    return (
      <Fragment>
        <div className="button-row center">
          <div className="cancel button"
            data-cy="cancel-button"
            onClick={ () => this.props.history.push('/account/animals')}>
            Cancel
          </div>
          <div className={ saveButtonClass }
            data-cy="save-button"
            onClick={ this.handleSaveClicked.bind(this) }>
            Save
          </div>
          <div className={ deleteButtonClass }
            data-cy="delete-button"
            onClick={ () => this.setState({ showDelete: true }) }>
            Delete
          </div>
        </div>
        { error }
      </Fragment>
    );
  }
  renderGBIFields (element) {
    return Object.keys(this.props.scoreDefinitions).map(key => ({
      key,
      name: this.props.scoreDefinitions[key].name,
      category: this.props.scoreDefinitions[key].category,
      scores: this.props.scoreDefinitions[key].scoreCard.map(
        score => score.score
      )
    })).filter(factor => {
      const type = this.state.animal && this.state.animal.type ?
        this.state.animal.type : null;
      if ((type !== `YoungBulls` && factor.key === 'bullFertility') ||
      (type !== `BreedingCows` && factor.key === 'cowFertility')) {
        return false;
      }
      return factor.category === element;
    }).map(factor => {
      let score = '-';
      try {
        score = this.state.animal[element][factor.key].score;
      } catch (error) { /**/ }
      const setNewScore = score => {
        this.updateGBIScore(element, factor.key, score);
      };
      return <AnimalScore key={ factor.key }
        score={ score }
        update={ setNewScore }
        factor={ factor } />;
    });
  }
  renderGBIScoreError () {
    if (this.state.showErrors !== true) return null;
    let showError = false;
    try {
      Object.keys(this.state.errors).forEach(errorKey => {
        if (errorKey.includes('score_') &&
        this.props.scoreDefinitions[errorKey.replace('score_', '')]) {
          showError = true;
        }
      });
    } catch (error) { /* */ }
    return showError ? (
      <div className="error gbi-scores-error">
        Values for all factors are required
      </div>
    ) : null;
  }
  renderDocuments () {
    const documents = this.state.animal && this.state.animal.documents ?
      this.state.animal.documents : {};
    let error = this.getError('documents') ? (
      <div className="errors" data-cy="document-error">
        { this.getError('documents') }
      </div>
    ) : null;
    return (
      <div className="documents" data-cy="animal-document-section">
        <h4 data-cy="auth-doc-title">Authentication documents</h4>
        <div className="document-buttons">
          <DocumentUpload
            removeFunc={ this.removeDocument.bind(this) }
            ownerId={ this.state.animal ? this.state.animal.orgId : '' }
            uploaded={ this.addDocumentRef.bind(this) } />
          <FarmDocumentSelector animal={ this.state.animal }
            add={ this.addFarmDocument.bind(this) } />
        </div>
        <DocumentList documents={ documents }
          updateDocuments={ this.updateDocuments.bind(this) }
          removeDocument={ this.deleteDocumentRef.bind(this) } />
        { error }
      </div>
    );
  }
  renderImages () {
    return null;
    // const images = this.getAnimalAttribute('images') || [];
    // const orgId = this.state.animal && this.state.animal.orgId ?
    //   this.state.animal.orgId : 'error';
    // return (
    //   <Images images={ images }
    //     addImage={ this.addImage.bind(this) }
    //     deleteImage={ this.deleteImage.bind(this) }
    //     moveImage={ this.moveImage.bind(this) }
    //     ownerId={ orgId }
    //     maxImages={ 10 } />
    // );
  }
  renderBlanket () {
    const animal = this.props.animals[this.props.match.params.animalId];
    const isSaving = animal ? !!animal.saving : false;
    let className = 'animal-api-blanket';
    if (this.isLoading() || isSaving) className += ' visible';
    return (
      <div className={ className } >
        <LoadingIcon />
      </div>
    );
  }
  renderBackButton () {
    const id = this.props.match.params.animalId;
    let path = this.props.match.url.replace('/' + id, '');
    return (
      <Link className="back-to-animals-link"
        data-cy="back-to-animals-link"
        to={ path }>
        <ChevronIcon /> Back to animals
      </Link>
    );
  }
  renderLastUpdate () {
    let updateInfo = null;
    try {
      if (this.state.animal.lastUpdate) {
        const update = this.state.animal.lastUpdate;
        updateInfo = (
          <span className="update-info">
            { `Last changed ${moment(update.dateTime).fromNow()} 
              by ${update.userName || 'unknown'}` }
          </span>
        );
      }
    } catch (error) { /* */ }
    return updateInfo;
  }
  renderWeightDateField () {
    if (!this.getAnimalAttribute('weight')) return null;

    const current = this.getAnimalAttribute('weightDate');
    const value = current ? new Date(current) : undefined;
    return (
      <DateField name="Date of Weighing"
        value={ value }
        testId="animal-weight-date"
        error={ this.getError('weightDate') }
        update={ value => this.updateFormField('weightDate', value) } />
    );
  }
  renderBeefBookOnlyFields () {
    if (!this.state.animal ||
      this.state.animal.liveOnBeefBook !== true) return null;
    return (
      <Fragment>
        <DropDownSelectField
          testId="animal-category-dropdown"
          options={ this.getTypeOptions() }
          name="Animal category"
          error={ this.getError('type') }
          update={ value => this.updateFormField('type', value) } />
        { this.renderPrice() }
        { this.renderBirthDate() }
        <TextField name="Location"
          value={ this.getAnimalAttribute('location') }
          error={ this.getError('location') }
          update={ value => this.updateFormField('location', value) } />
        { this.renderLiveStockFields() }
        <NumberField name="Daily Weight Gain"
          value={ this.getAnimalAttribute('dailyGain')}
          testId="animal-daily-gain"
          error={ this.getError('dailyGain') }
          update={ value => this.updateFormField('dailyGain', value) } />
      </Fragment>
    );
  }
  renderLiveStockFields () {
    if (!this.state.animal ||
      this.state.animal.liveOnBeefBook !== true ||
      this.state.animal.type === 'FinishedCattle') return null;
    return (
      <Fragment>
        <NumberField name="EBV Terminal Index"
          testId="EBVTI"
          value={ this.getAnimalAttribute('EBV', 'TIscore') }
          error={ this.getError('EBVTIScore') }
          update={ value => this.updateEBVField('TIscore', value) } />
        <NumberField name="EBV Self Replacing Index"
          testId="EBVSRI"
          value={ this.getAnimalAttribute('EBV', 'SRIscore') }
          error={ this.getError('EBVSRIScore') }
          update={ value => this.updateEBVField('SRIscore', value) } />
        <TextField name="EBV Rank"
          testId="EBVRank"
          value={ this.getAnimalAttribute('EBV', 'rank') }
          error={ this.getError('EBVRank') }
          update={ value => this.updateEBVField('rank', value) } />
        <NumberField name="Birth Weight"
          value={ this.getAnimalAttribute('birthWeight')}
          error={ this.getError('birthWeight') }
          update={ value => this.updateFormField('birthWeight', value) } />
      </Fragment>
    );
  }
  renderVideo () {
    let content = null;
    if (this.state.animal &&
      this.state.animal.orgVideo &&
      this.state.animal.orgVideo.base) {
      content = <ExistingVideo videoData={ this.state.animal.orgVideo } />;
    } else if (this.state.animal && this.state.animal.orgId) {
      const link = (
        <Link to={ `/account/orgs/${this.state.animal.orgId}` }
          className="standard-link"
          onClick={ () => window.scrollTo({ top: 0, behavior: 'auto' }) }>
          farm admin
        </Link>
      );
      content = (
        <p className="add-video-link">
        Add a video on your { link } page
        </p>
      );
    } else {
      content = (
        <p className="no video">No video found</p>
      );
    }
    return (
      <div className="video-admin" data-cy="video-admin-section">
        <h4>Farm Video</h4>
        { content }
      </div>
    );
  }
  renderOrg () {
    try {
      return (
        <h5 className="org">
          { this.props.orgs[this.state.animal.orgId].name }
        </h5>
      );
    } catch (error) {
      return null;
    }
  }
  render () {
    if (this.isLoading()) {
      return (
        <div id="animal" className="loading-page">
          <LoadingIcon />
        </div>
      );
    }
    const breedOptions = breeds.map(breed => ({
      value: breed,
      name: breed,
      selected: this.getAnimalAttribute('breed') === breed
    }));
    const allowPrompt = this.unsavedChanges() &&
      !this.state.ignorePromptOnRouteChange;
    const statusClass = this.getAnimalAttribute('liveOnGBI') ?
      'status-icon live' : 'status-icon';
    return (
      <Fragment>
        { this.renderBlanket() }
        <Prompt
          when={ allowPrompt }
          message={location =>
            'You have unsaved changes - you will lose them if you continue'}
        />
        <div id="animal" data-cy="admin-animal-page">
          { this.renderBackButton() }
          <h2 data-cy="animal-page-title">
            { this.getAnimalAttribute('name') || '' }
          </h2>
          { this.renderOrg() }
          <div className="status-wrapper">
            <div className={ statusClass }></div>
            { this.renderLastUpdate() }
          </div>
          <form className="animal-form beefbook-sales"
            onSubmit={ this.handleSubmit.bind(this) }>
            { this.renderLiveOnBeefBookField() }
            { this.renderLiveOnGBIField() }
            { this.renderAvailabilityStatus() }
            { this.renderAvailableDate() }
          </form>
          <form className="animal-form"
            onSubmit={ this.handleSubmit.bind(this) }>
            {/* { this.renderAvailabilityField() } */}
            <FarmSelectionDropDown farms={this.props.orgs}
              farmId={ this.state.animal ? this.state.animal.orgId : '' }
              setFarm={ this.setOrg.bind(this) } />
            <TextField name="Name"
              value={ this.getAnimalAttribute('name') }
              testId="animal-name-input"
              error={ this.getError('name') }
              update={ value => this.updateFormField('name', value) } />
            <TextField name="Tag"
              value={ this.getAnimalAttribute('tag') }
              testId="animal-tag-input"
              error={ this.getError('tag') }
              update={ value => this.updateFormField('tag', value) } />
            <DropDownSelectField
              options={ breedOptions }
              name="Breed"
              testId="animal-breed-dropdown"
              error={ this.getError('breed') }
              update={ value => this.updateFormField('breed', value) } />
            <NumberField name="IMF %"
              value={ this.getAnimalAttribute('IMF')}
              testId="animal-imf-input"
              error={ this.getError('IMF') }
              update={ this.handleIMFUpdate.bind(this) } />
            <NumberField name="Weight"
              value={ this.getAnimalAttribute('weight')}
              error={ this.getError('weight') }
              testId="animal-weight-input"
              update={ value => this.updateFormField('weight', value) } />
            { this.renderWeightDateField() }
            { this.renderBeefBookOnlyFields() }
          </form>
          { this.renderDocuments() }
          { this.renderImages() }
          { this.renderVideo() }
          {/* <VideoAdminSection
            videoData = { this.getAnimalAttribute('videoData') }
            update={ data => this.updateFormField('videoData', data) }
            animalId= { this.getAnimalAttribute('id') }
            orgId={ this.getAnimalAttribute('orgId')} /> */}
          <AnimalScoreDisplay
            animal={ this.state.animal }
            selectElement={ element => this.setState({ tab: element }) } >
            { this.renderGBIScoreError() }
            { this.renderGBIFields(this.state.tab) }
          </AnimalScoreDisplay>
          { this.renderDeleteModal() }
          { this.renderButtons() }
          <ErrorBoundary>
            <AnimalQRSection animal={ this.state.animal } />
          </ErrorBoundary>
        </div>
      </Fragment>
    );
  }
}

Animal.propTypes = {
  animals: PropTypes.object,
  orgs: PropTypes.object,
  loadingAll: PropTypes.bool,
  individualsLoading: PropTypes.object,
  add: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  fetchOrgs: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      animalId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object,
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Animal);
