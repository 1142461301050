import React from 'react';
import PropTypes from 'prop-types';
import './existing-video.scss';

const ExistingVideo = ({ videoData }) => {
  let existingVideo = null;
  if (videoData && videoData.base) {
    const sources = [];
    if (videoData.mp4) {
      sources.push(
        <source type="video/mp4"
          src={ videoData.mp4 }
          key='mp4' />
      );
    } else {
      sources.push(
        <source type="video/mp4"
          src={ videoData.base }
          key="mp4" />
      );
    }
    ['hls', 'dash'].forEach(key => {
      const type = key === 'dash' ? 'application/dash+xml'
        : 'application/x-mpegURL';
      sources.push(
        <source type={ type }
          src={ videoData[key] }
          key={ key } />
      );
    });
    existingVideo = (
      <video controls className="existing-video"
        poster={ videoData.thumb }>
        { sources }
      </video>
    );
  }
  return existingVideo;
};

ExistingVideo.propTypes = {
  videData: PropTypes.object
};

export default ExistingVideo;
