import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import VerificationDocument from './VerificationDocument';
import docsObjToArray from '../util/docsObjToArray';
import reorder from '../util/reorder';
import docArrayToObj from '../util/docArrayToObj';

const addPositionAttributes = docsObj => {
  const docArray = docsObjToArray(docsObj || {});
  return docArrayToObj(docArray);
};

class DocumentList extends Component {
  onDragEnd (result) {
    if (!result.destination) {
      return;
    }
    const docArray = docsObjToArray(this.props.documents);
    const reorderedDocArray = reorder(
      docArray,
      result.source.index,
      result.destination.index
    );
    const documents = docArrayToObj(reorderedDocArray);
    this.props.updateDocuments(documents);
    this.setState({ documents });
  }
  updateDocument (document) {
    const docs = addPositionAttributes(this.props.documents);
    docs[document.key] = document;
    this.setState({ documents: docs });
    this.props.updateDocuments(docs);
  }
  removeDocument (key) {
    const docs = addPositionAttributes(this.props.documents);
    delete docs[key];
    this.setState({ documents: docs });
    this.props.removeDocument(key);
  }
  render () {
    const items = docsObjToArray(this.props.documents);
    return (
      <DragDropContext onDragEnd={ this.onDragEnd.bind(this) }>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef} >
              {items.map((item, index) => (
                <Draggable
                  key={item.key}
                  draggableId={`drag-item-${item.key}` }
                  index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="verification-doc-drag-wrapper"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={ provided.draggableProps.style } >
                      <VerificationDocument
                        isDragging={ snapshot.isDragging }
                        document={ item }
                        update={ this.updateDocument.bind(this) }
                        deleteDoc={ this.removeDocument.bind(this) } />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

DocumentList.propTypes = {
  documents: PropTypes.object,
  updateDocuments: PropTypes.func,
  removeDocument: PropTypes.func
};

export default DocumentList;
