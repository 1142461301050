import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './admin-form-field.scss';
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment';

import 'moment/locale/en-gb';
import CalendarIcon from '../util/icons/components/CalendarIcon';

class DateField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      error: ''
    };
    this.inputRef = createRef();
  }
  getError () {
    return this.props.error || this.state.error || null;
  }
  handleChange (selectedDate, modifiers, dayPickerInput) {
    if (selectedDate) {
      let str = selectedDate.toISOString();
      this.props.update(str);
      this.setState({ error: '' });
    } else {
      const str = dayPickerInput.input.value;
      try {
        this.props.update(new Date(str).toISOString());
        this.setState({ error: '' });
      } catch (error) {
        this.setState({ error: 'invalid date' });
      }
    }
  }
  render () {
    let labelClass = 'form-label';
    if (this.getError()) labelClass += ' error';
    const dayPickerProps = {
      formatDate: formatDate,
      parseDate: parseDate,
      format: 'LL',
      dayPickerProps: {
        locale: 'en-gb',
        localeUtils: MomentLocaleUtils
      },
      onDayChange: this.handleChange.bind(this)
    };
    if (this.props.value) {
      dayPickerProps.placeholder = `${formatDate(new Date(), 'LL', 'en-gb')}`;
      dayPickerProps.value = this.props.value;
    } else {
      dayPickerProps.placeholder = this.props.placeholder || 'select a date';
    }
    return (
      <div className="form-field-wrapper" data-cy={ this.props.testId }>
        <label className={ labelClass }>
          { this.props.name }
        </label>
        <div className="date-input-wrapper">
          <DayPickerInput { ...dayPickerProps } />
          <CalendarIcon />
        </div>
        <div className="field-error-text">{ this.getError() }</div>
        { this.props.children }
      </div>
    );
  }
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  testId: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  error: PropTypes.string,
  update: PropTypes.func,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default DateField;
