import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import './admin-form-field.scss';

class TextField extends Component {
  constructor (props) {
    super(props);
    const value = props.value || props.value === 0 ? props.value : '';
    this.state = {
      inFocus: false,
      value
    };
    this.inputRef = createRef();
  }
  static getDerivedStateFromProps (props, state) {
    if (!state.inFocus) {
      return { ...state, value: props.value };
    }
    return state;
  }
  handleChange (event) {
    this.setState({ value: event.target.value });
  }
  handleFocus () {
    this.setState({ inFocus: true });
  }
  handleBlur () {
    const value = this.inputRef.current.value;
    this.setState({ inFocus: false, value }, () => {
      this.props.update(value);
    });
  }
  getError () {
    return !this.state.inFocus && this.props.error ?
      this.props.error : null;
  }
  render () {
    let labelClass = 'form-label';
    if (this.getError()) labelClass += ' error';
    const error = this.props.error ? (
      <div className="field-error-text" data-cy="field-error-text">
        { this.getError() }
      </div>
    ) : null;
    return (
      <div className="form-field-wrapper">
        <label className={ labelClass }>
          { this.props.name }
        </label>
        <input
          className='form-text-box'
          data-cy={ this.props.testId }
          value={ this.state.value || '' }
          onChange={ this.handleChange.bind(this) }
          ref={ this.inputRef }
          onFocus={ this.handleFocus.bind(this) }
          onBlur={ this.handleBlur.bind(this) } />
        { error }
      </div>
    );
  }
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  update: PropTypes.func.isRequired,
  testId: PropTypes.string
};

export default TextField;
