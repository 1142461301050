/* eslint-disable max-len */
/* eslint-disable quotes */

const db = {
  "Netherton Americano M703": {
    "name": "Netherton Americano M703",
    "tag": "UK 542697 200703",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252423212020&9=5E505A5E",
    "sire": "NETHERTON MR RADER J527",
    "dam": "NETHERTON ANNIE H474"
  },
  "NETHERTON MR RADER J527": {
    "name": "NETHERTON MR RADER J527",
    "tag": "UK542697 100527",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2622582F2F2F",
    "sire": "DYLEMMA RADAR W42",
    "dam": "NETHERTON MISSIE A114"
  },
  "DYLEMMA RADAR W42": {
    "name": "DYLEMMA RADAR W42",
    "tag": "CBJW42(AU)31",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262720272E20"
  },
  "NETHERTON MISSIE A114": {
    "name": "NETHERTON MISSIE A114",
    "tag": "UK 542697 100114",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A592026242F"
  },
  "NETHERTON ANNIE H474": {
    "name": "NETHERTON ANNIE H474",
    "tag": "UK 542697 400474",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262427252224",
    "sire": "TC GRID TOPPER 355",
    "dam": "SOUTHLAND AWESTRUCK 117R"
  },
  "TC GRID TOPPER 355": {
    "name": "TC GRID TOPPER 355",
    "tag": "14526645(US)32",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262558232722"
  },
  "SOUTHLAND AWESTRUCK 117R": {
    "name": "SOUTHLAND AWESTRUCK 117R",
    "tag": "1295111(CA)32",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262558232726"
  },
  "Standlynch Fine Girl P797": {
    "name": "Standlynch Fine Girl P797",
    "tag": "UK 323963 100797",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242523272126&9=5E50585F",
    "sire": "BOREWELL MISTER ECCLES L462",
    "dam": "STANDLYNCH FINE GIRL L484"
  },
  "BOREWELL MISTER ECCLES L462": {
    "name": "BOREWELL MISTER ECCLES L462",
    "tag": "UK 106816 200462",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B25595821232E",
    "sire": "B/R NEW FRONTIER 095",
    "dam": "ANKONY MISS EFFIE 8433"
  },
  "B/R NEW FRONTIER 095": {
    "name": "B/R NEW FRONTIER 095",
    "tag": "13588640(US)28",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5A59212026"
  },
  "ANKONY MISS EFFIE 8433": {
    "name": "ANKONY MISS EFFIE 8433",
    "tag": "16060002(US)34",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26215827252E"
  },
  "STANDLYNCH FINE GIRL L484": {
    "name": "STANDLYNCH FINE GIRL L484",
    "tag": "UK 323963 300484",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B25592225202E",
    "sire": "BLACKHAUGH EVERY TIME H842",
    "dam": "STANDLYNCH FINE GIRL H267"
  },
  "BLACKHAUGH EVERY TIME H842": {
    "name": "BLACKHAUGH EVERY TIME H842",
    "tag": "UK 562121 300842",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262426232725"
  },
  "STANDLYNCH FINE GIRL H267": {
    "name": "STANDLYNCH FINE GIRL H267",
    "tag": "UK 323963 300267",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262522222523"
  },
  "Rawburn Jagger Eric R094": {
    "name": "Rawburn Jagger Eric R094",
    "tag": "UK 562106 402094",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242422242724&9=5B5E5858",
    "sire": "RAWBURN BOSS HOGG N630",
    "dam": "RAWBURN JOY ERICA L251"
  },
  "RAWBURN BOSS HOGG N630": {
    "name": "RAWBURN BOSS HOGG N630",
    "tag": "UK 562106 201630",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252323222221",
    "sire": "TC FREEDOM 104 (SS) (IMP)",
    "dam": "HOFF BLACKBIRD 594 5218 (ET) (IMP)"
  },
  "RAWBURN JOY ERICA L251": {
    "name": "RAWBURN JOY ERICA L251",
    "tag": "UK 562106 101251",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255959582E21",
    "sire": "RAWBURN TRANSFORMER",
    "dam": "RAWBURN JOY ERICA J988"
  },
  "TC FREEDOM 104 (SS) (IMP)": {
    "name": "TC FREEDOM 104 (SS) (IMP)",
    "tag": "13977765(US)29",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265A2322272F"
  },
  "HOFF BLACKBIRD 594 5218 (ET) (IMP)": {
    "name": "HOFF BLACKBIRD 594 5218 (ET) (IMP)",
    "tag": "15141038(US)36",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252726252523"
  },
  "RAWBURN TRANSFORMER": {
    "name": "RAWBURN TRANSFORMER",
    "tag": "EJR.W52.22",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242422242724&9=5B5E5858"
  },
  "WARSON BLACK BITHIAH S088": {
    "name": "WARSON BLACK BITHIAH S088",
    "tag": "UK 375444 700088",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B232621242423&9=5B5D515B",
    "sire": "RIBBLE ERITREAN N031",
    "dam": "RIBBLE BLACK BELINDA L539"
  },
  "RIBBLE ERITREAN N031": {
    "name": "RIBBLE ERITREAN N031",
    "tag": "UK 182746 102031",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252124232321",
    "sire": "FORDEL ENNISKILLEN H512",
    "dam": "RIBBLE ELKA G030"
  },
  "RIBBLE BLACK BELINDA L539": {
    "name": "RIBBLE BLACK BELINDA L539",
    "tag": "UK 182746 601539",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255921202421",
    "sire": "RIBBLE PLOVER D472",
    "dam": "RIBBLE BLACK BELINDA B192"
  },
  "FORDEL ENNISKILLEN H512": {
    "name": "FORDEL ENNISKILLEN H512",
    "tag": "UK 542511 701512",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262425262222"
  },
  "RIBBLE PLOVER D472": {
    "name": "RIBBLE PLOVER D472",
    "tag": "UK 182746 300472",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2523252122"
  },
  "RIBBLE BLACK BELINDA B192": {
    "name": "RIBBLE BLACK BELINDA B192",
    "tag": "UK 182746 300192",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B21232F232E"
  },
  "RIBBLE ELKA G030": {
    "name": "RIBBLE ELKA G030",
    "dam": "UK 182746 101030",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2625582F212E"
  },
  "STANDLYNCH RAMEAU P791": {
    "name": "STANDLYNCH RAMEAU P791",
    "tag": "UK 323963 200791",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242523272221&9=5B5D505C",
    "sire": "NETHERALLAN PETER PERSHORE E052",
    "dam": "STANDLYNCH RAASAY K401"
  },
  "NETHERALLAN PETER PERSHORE E052": {
    "name": "NETHERALLAN PETER PERSHORE E052",
    "tag": "UK 541616 100052",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923252526",
    "sire": "ANKONIAN ELIXIR 100 (AI) (IMP)",
    "dam": "NIGHTINGALE PRIDE OF PERSHORE S44"
  },
  "ANKONIAN ELIXIR 100 (AI) (IMP)": {
    "name": "ANKONIAN ELIXIR 100 (AI) (IMP)",
    "tag": "12758219(US)23",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5A59222124"
  },
  "NIGHTINGALE PRIDE OF PERSHORE S44": {
    "name": "NIGHTINGALE PRIDE OF PERSHORE S44",
    "tag": "RWZ.S44.18",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B232025242D"
  },
  "STANDLYNCH RAASAY K401": {
    "name": "STANDLYNCH RAASAY K401",
    "tag": "UK 323963 400401",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262127222F22",
    "sire": "FORDEL ENTERPRISE D674",
    "dam": "STANDLYNCH RAASAY F166"
  },
  "FORDEL ENTERPRISE D674": {
    "name": "FORDEL ENTERPRISE D674",
    "tag": "UK 542511 200674",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A252127222D"
  },
  "STANDLYNCH RAASAY F166": {
    "name": "STANDLYNCH RAASAY F166",
    "tag": "UK 323963 700166",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265827582520"
  },
  "RAWBURN EVENTIDE R134": {
    "name": "RAWBURN EVENTIDE R134",
    "tag": "UK 562106 202134",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242420232E2E&9=5B5D5F5D",
    "sire": "RAWBURN BOSS HOGG N630",
    "dam": "RAWBURN EVENTIDE J997"
  },
  "RAWBURN EVENTIDE J997": {
    "name": "RAWBURN EVENTIDE J997",
    "tag": "UK 562106 600997",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26232423252F",
    "sire": "RAWBURN ENIGMA G653",
    "dam": "RAWBURN EVENTIDE F565"
  },
  "RAWBURN ENIGMA G653": {
    "name": "RAWBURN ENIGMA G653",
    "tag": "UK 562106 500653",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26265823222D"
  },
  "RAWBURN EVENTIDE F565": {
    "name": "RAWBURN EVENTIDE F565",
    "tag": "UK 562106 100565",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2658252F2323"
  },
  "RAWBURN BOSS LADY R201": {
    "name": "RAWBURN BOSS LADY R201",
    "tag": "UK 562106 602201",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B24222121242F&9=5B5D5F59",
    "sire": "RAWBURN BIG TIMBER N627",
    "dam": "RAWBURN BOSS LADY N726"
  },
  "RAWBURN BIG TIMBER N627": {
    "name": "RAWBURN BIG TIMBER N627",
    "tag": "UK 562106 601627",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252323222224",
    "sire": "VERMILION DATELINE 7078 (SS) (IMP)",
    "dam": "HOFF BLACKBIRD 594 5218 (ET) (IMP)"
  },
  "RAWBURN BOSS LADY N726": {
    "name": "RAWBURN BOSS LADY N726",
    "tag": "UK 562106 701726",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2523212F2326",
    "sire": "VERMILION DATELINE 7078 (SS) (IMP)",
    "dam": "HOFF BLACKBIRD 594 5218 (ET) (IMP)"
  },
  "VERMILION DATELINE 7078 (SS) (IMP)": {
    "name": "VERMILION DATELINE 7078 (SS) (IMP)",
    "tag": "12810693(US)28",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5A59222121"
  },
  "STANDLYNCH ROSEBUD N703": {
    "name": "STANDLYNCH ROSEBUD N703",
    "tag": "UK 323963 500703",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2459252F242F&9=5B5D5E58",
    "sire": "BLACKHAUGH KARAPAPA H854",
    "dam": "STANDLYNCH ROSEBUD K395"
  },
  "BLACKHAUGH KARAPAPA H854": {
    "name": "BLACKHAUGH KARAPAPA H854",
    "tag": "UK 562121 100854",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262358202020",
    "sire": "NIGHTINGALE PAPA A069",
    "dam": "BLACKHAUGH KARAMA F604"
  },
  "STANDLYNCH ROSEBUD K395": {
    "name": "STANDLYNCH ROSEBUD K395",
    "tag": "UK 323963 500395",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26212722202F",
    "sire": "STANDLYNCH ELTON F181",
    "dam": "STANDLYNCH ROSEBUD G246"
  },
  "NIGHTINGALE PAPA A069": {
    "name": "NIGHTINGALE PAPA A069",
    "tag": "UK 302307 400069",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5A2720212F"
  },
  "BLACKHAUGH KARAMA F604": {
    "name": "BLACKHAUGH KARAMA F604",
    "tag": "UK 562121 300604",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265822262221"
  },
  "STANDLYNCH ELTON F181": {
    "name": "STANDLYNCH ELTON F181",
    "tag": "UK 323963 100181",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262727252F2E"
  },
  "STANDLYNCH ROSEBUD G246": {
    "name": "STANDLYNCH ROSEBUD G246",
    "tag": "UK  323963 300246",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262525272E23",
    "sire": "FORDEL ENTERPRISE D674",
    "dam": "STANDLYNCH ROSEBUD C048"
  },
  "STANDLYNCH ROSEBUD R817": {
    "name": "STANDLYNCH ROSEBUD R817",
    "tag": "UK 323963 700817",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2424252F2E20&9=5B5D5D5F",
    "sire": "STANDLYNCH RIGHT TIME L499",
    "dam": "STANDLYNCH ROSEBUD G246"
  },
  "STANDLYNCH RIGHT TIME L499": {
    "name": "STANDLYNCH RIGHT TIME L499",
    "tag": "UK 323963 400499",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255922252E25",
    "sire": "BLACKHAUGH EVERY TIME H842",
    "dam": "STANDLYNCH RAASAY H313"
  },
  "STANDLYNCH RAASAY H313": {
    "name": "STANDLYNCH RAASAY H313",
    "tag": "UK 323963 700313",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26235821252F"
  },
  "STANDLYNCH ROSEBUD C048": {
    "name": "STANDLYNCH ROSEBUD C048",
    "tag": "UK 323963 100048",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5A252F2124"
  },
  "RAWBURN KINANNIE R174": {
    "name": "RAWBURN KINANNIE R174",
    "tag": "UK 562106 702174",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B24222224232E&9=5B5D5C5B",
    "sire": "RAWBURN DUBLIN K218",
    "dam": "RAWBURN KINANNIE J975"
  },
  "RAWBURN DUBLIN K218": {
    "name": "RAWBURN DUBLIN K218",
    "tag": "UK 562106 301218",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26212226202D",
    "sire": "RAWBURN EL TORRO H774",
    "dam": "RAWBURN DUCHESS WINDSOR H818"
  },
  "RAWBURN EL TORRO H774": {
    "name": "RAWBURN EL TORRO H774",
    "tag": "UK 562106 700774",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262521252720"
  },
  "RAWBURN DUCHESS WINDSOR H818": {
    "name": "RAWBURN DUCHESS WINDSOR H818",
    "tag": "UK 562106 200818",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262521232024"
  },
  "RAWBURN KINANNIE J975": {
    "name": "RAWBURN KINANNIE J975",
    "tag": "UK 562106 500975",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262324582123",
    "sire": "KMK ALLIANCE 6595 187 (SS) (IMP)",
    "dam": "ARGWEN KINANNIE 12E (ET) (IMP)"
  },
  "KMK ALLIANCE 6595 187 (SS) (IMP)": {
    "name": "KMK ALLIANCE 6595 187 (SS) (IMP)",
    "tag": "13346328(US)31",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262726582223"
  },
  "ARGWEN KINANNIE 12E (ET) (IMP)": {
    "name": "ARGWEN KINANNIE 12E (ET) (IMP)",
    "tag": "845104(CA)32",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26262222202D"
  },
  "GEAR ELLA P322": {
    "name": "GEAR ELLA P322",
    "tag": "UK 384042 600322",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2458582F2222&9=5B5D5B5F",
    "sire": "NETHERTON BIG BEN M656",
    "dam": "GEAR ELLA M266 #UK384042 600266"
  },
  "NETHERTON BIG BEN M656": {
    "name": "NETHERTON BIG BEN M656",
    "tag": "UK 542697 400656",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252759222F22",
    "sire": "HF EL TIGRE 28U (AI) (IMP)",
    "dam": "NETHERTON BEAUTY J533 #UK542697 700533"
  },
  "GEAR ELLA M266 #UK384042 600266": {
    "name": "GEAR ELLA M266",
    "tag": "UK384042 600266",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252727232621",
    "sire": "NETHERTON ARCHIE J501",
    "dam": "GEAR ELOISE E138 #UK384042 400138"
  },
  "NETHERTON ARCHIE J501": {
    "name": "NETHERTON ARCHIE J501",
    "tag": "UK542697 300501",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262322212723",
    "sire": "TC GRID TOPPER 355 (SS) (IMP) #14526645(US)32",
    "dam": "SOUTHLAND AWESTRUCK 117R (ET) (IMP) #1295111(CA)32"
  },
  "GEAR ELOISE E138 #UK384042 400138": {
    "name": "GEAR ELOISE E138 #UK384042 400138",
    "tag": "GEAR ELOISE E138 #UK384042 400138",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923222026",
    "sire": "NIGHTINGALE PRETENDER X35 #RWZ.X35.23",
    "dam": "EDNA 4TH OF LANSON #RAL.V4.21"
  },
  "HF EL TIGRE 28U (AI) (IMP)": {
    "name": "HF EL TIGRE 28U (AI) (IMP)",
    "tag": "1469322(CA)34",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262159262522"
  },
  "NETHERTON BEAUTY J533 #UK542697 700533": {
    "name": "NETHERTON BEAUTY J533",
    "tag": "UK 542697 700533",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26222020252D"
  }, //Bull
  "WARSON ELLIOTT U149": {
    "name": "WARSON ELLIOTT U149",
    "tag": "UK 375444 500149",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5826232523&9=5250595A",
    "sire": "OAKCHURCH DUSTER M109",
    "dam": "RIBBLE ELKA R907"
  }, // Sire
  "OAKCHURCH DUSTER M109": {
    "name": "OAKCHURCH DUSTER M109",
    "tag": "UK 313622 300109",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252420252323",
    "sire": "TE MANIA BERKLEY B1 (AI) (IMP)",
    "dam": "OAKCHURCH DARLING H080"
  }, // 1G-S
  "TE MANIA BERKLEY B1 (AI) (IMP)": {
    "name": "TE MANIA BERKLEY B1 (AI) (IMP)",
    "tag": "VTMB1(AU)36",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255923582522",
    "sire": "TE MANIA YORKSHIRE Y437",
    "dam": "E MANIA LOWAN Z53"
  }, // 2G-S
  "TE MANIA YORKSHIRE Y437": {
    "name": "TE MANIA YORKSHIRE Y437",
    "tag": "VTMY437(AU)",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255923582526"
  }, // 2G-D
  "E MANIA LOWAN Z53": {
    "name": "E MANIA LOWAN Z53",
    "tag": "VTMZ53(AU)",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255923582523"
  }, // 1G-D
  "OAKCHURCH DARLING H080": {
    "name": "OAKCHURCH DARLING H080",
    "tag": "UK 301703 500080",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26252120222D",
    "sire": "RAWBURN TRANSFORMER",
    "dam": "OAKCHURCH DAWN D012"
  }, // 2G-S
  "RAWBURN TRANSFORMER": {
    "name": "RAWBURN TRANSFORMER",
    "tag": "EJR.W52.22",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5820272123"
  }, // 2G-D
  "OAKCHURCH DAWN D012": {
    "name": "OAKCHURCH DAWN D012",
    "tag": "UK 301703 700012",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265A24242E25"
  }, // Dire
    "RIBBLE ELKA R907": {
    "name": "RIBBLE ELKA R907",
    "tag": "UK 182746 301907",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2423582F2123",
    "sire": "RIBBLE EUSTON L544",
    "dam": "RIBBLE ELKA K429"
  }, // 1G-S
  "RIBBLE EUSTON L544": {
    "name": "RIBBLE EUSTON L544",
    "tag": "UK 182746 401544",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255921202325",
    "sire": "FORDEL ENNISKILLEN H512",
    "dam": "RIBBLE ESSIE J309"
  }, // 2G-S
  "FORDEL ENNISKILLEN H512": {
    "name": "FORDEL ENNISKILLEN H512",
    "tag": "UK 542511 701512",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262425262222"
  }, // 2G-D
  "RIBBLE ESSIE J309": {
    "name": "RIBBLE ESSIE J309",
    "tag": "UK 182746 701309",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262258242126"
  }, // 1G-D
  "RIBBLE ELKA K429": {
    "name": "RIBBLE ELKA K429",
    "tag": "UK 182746 101429",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26212025262E",
    "sire": "FORDEL DOMINANT D576",
    "dam": "RIBBLE ELKA D485"
  }, // 2G-S
  "FORDEL DOMINANT D576": {
    "name": "FORDEL DOMINANT D576",
    "tag": "UK 542511 200576",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A252127232E"
  }, // 2G-D
  "RIBBLE ELKA D485": {
    "name": "RIBBLE ELKA D485",
    "tag": "UK 182746 200485",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2523252220"
  }, // Bull
  "Warson Elon U153": {
    "name": "Warson Elon U153",
    "tag": "UK 375444 200153",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5826232423&9=525F5F5E",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "RIBBLE ETHEL GEM L635"
  }, // Sire
  "RAWBURN JAGGER ERIC R094": {
    "name": "RAWBURN JAGGER ERIC R094",
    "tag": "UK 562106 402094",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242422242724",
    "sire": "RAWBURN BOSS HOGG N630",
    "dam": "RAWBURN JOY ERICA L251"
  }, // 1G-S
  "RAWBURN BOSS HOGG N630": {
    "name": "RAWBURN BOSS HOGG N630",
    "tag": "UK 562106 201630",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252323222221",
    "sire": "TC FREEDOM 104 (SS) (IMP)",
    "dam": "HOFF BLACKBIRD 594 5218 (ET) (IMP)"
  }, // 2G-S
  "TC FREEDOM 104 (SS) (IMP)": {
    "name": "TC FREEDOM 104 (SS) (IMP)",
    "tag": "13977765(US)29",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265A2322272F"
  }, // 2G-D
  "HOFF BLACKBIRD 594 5218 (ET) (IMP)": {
    "name": "HOFF BLACKBIRD 594 5218 (ET) (IMP)",
    "tag": "15141038(US)36",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252726252523"
  }, // 1G-D
  "RAWBURN JOY ERICA L251": {
    "name": "RAWBURN JOY ERICA L251",
    "tag": "UK 562106 101251",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255959582E21",
    "sire": "RAWBURN TRANSFORMER",
    "dam": "RAWBURN JOY ERICA J988"
  }, // 2G-S
  "RAWBURN TRANSFORMER ": {
    "name": "RAWBURN TRANSFORMER",
    "tag": "EJR.W52.22",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5820272123"
  }, // 2G-D
  "RAWBURN JOY ERICA J988": {
    "name": "RAWBURN JOY ERICA J988",
    "tag": "UK 562106 400988",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262324272E20"
  }, // Dire
    "RIBBLE ETHEL GEM L635": {
    "name": "RIBBLE ETHEL GEM L635",
    "tag": "UK 182746 401635",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2559212F2223",
    "sire": "FORDEL ESTONIA G222",
    "dam": "RIBBLE ETHEL GEM G980"
  }, // 1G-S
  "FORDEL ESTONIA G222": {
    "name": "FORDEL ESTONIA G222",
    "tag": "UK 542511 401222",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26255858212F",
    "sire": "BLELACK KIWI D002",
    "dam": "FORDEL EMPRESS D580"
  }, // 2G-S
  "BLELACK KIWI D002": {
    "name": "BLELACK KIWI D002",
    "tag": "UK 521115 201002",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2522222325"
  }, // 2G-D
  "FORDEL EMPRESS D580": {
    "name": "FORDEL EMPRESS D580",
    "tag": "UK 542511 600580",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A252126272E"
  }, // 1G-D
  "RIBBLE ETHEL GEM G980": {
    "name": "RIBBLE ETHEL GEM G980",
    "tag": "UK 182746 700980",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262627252223",
    "sire": "RIBBLE EMBLEM C374",
    "dam": "ETHEL GEM OF WULSTANS"
  }, // 2G-S
  "RIBBLE EMBLEM C374": {
    "name": "RIBBLE EMBLEM C374",
    "tag": "UK 182746 300374",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B21232F2120"
  }, // 2G-D
  "ETHEL GEM OF WULSTANS": {
    "name": "ETHEL GEM OF WULSTANS",
    "tag": "BFW.V17.21",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5959202E2F"
  }, // Bull
  "Warson Bedrich U155" : {
    "name": "Warson Bedrich U155",
    "tag": "UK 375444 400155",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5825252723&9=525C5B5D",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "RIBBLE BELLEMERE R346"
  }, // Dire
    "RIBBLE BELLEMERE R346": {
    "name": "RIBBLE BELLEMERE R346",
    "tag": "UK 182746 102346",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B24232727242F",
    "sire": "BLELACK LORD HANCOCK K453",
    "dam": "RIBBLE BELLEMERE J276"
  }, // 1G-S
  "BLELACK LORD HANCOCK K453": {
    "name": "BLELACK LORD HANCOCK K453",
    "tag": "UK 521115 402453",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262122582E2E",
    "sire": "NETHERALLAN PETER PERSHORE E052",
    "dam": "BLELACK LADY HEATHER D977"
  }, // 2G-S
  "NETHERALLAN PETER PERSHORE E052": {
    "name": "NETHERALLAN PETER PERSHORE E052",
    "tag": "UK 541616 100052",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923252526"
  }, // 2G-D
  "BLELACK LADY HEATHER D977": {
    "name": "BLELACK LADY HEATHER D977",
    "tag": "UK 521115 500977",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2522222F20"
  }, // 1G-D
  "RIBBLE BELLEMERE J276": {
    "name": "RIBBLE BELLEMERE J276",
    "tag": "UK 182746 201276",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262258242420",
    "sire": "FORDEL DOMINANT D576",
    "dam": "RIBBLE BELLEMERE E748"
  }, // 2G-S
  "FORDEL DOMINANT D576": {
    "name": "FORDEL DOMINANT D576",
    "tag": "UK 542511 200576",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A252127232E"
  }, // 2G-D
  "RIBBLE BELLEMERE E748": {
    "name": "RIBBLE BELLEMERE E748",
    "tag": "UK 182746 600748",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26585921272F"
  }, // Bull
  "Warson Equinity U157": {
    "name": "WARSON EQUINITY U157",
    "tag": "UK 375444 600157",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5825252720&9=525C5A5C",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "RIBBLE EQUIDORA L549"
  }, // Dire
  "RIBBLE EQUIDORA L549": {
    "name": "RIBBLE EQUIDORA L549",
    "tag": "UK 182746 201549",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B25592120232F",
    "sire": "RIBBLE PLOVER D472",
    "dam": "RIBBLE EQUIDORA G059"
  }, // 1G-S
  "RIBBLE PLOVER D472": {
    "name": "RIBBLE PLOVER D472",
    "tag": "UK 182746 300472",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2523252122",
    "sire": "P A R B DESIGN PLUS 97 (AI) (IMP)",
    "dam": "HEATH HOUSE PRIDE 8TH"
  }, // 2G-S
  "P A R B DESIGN PLUS 97 (AI) (IMP)": {
    "name": "P A R B DESIGN PLUS 97 (AI) (IMP)",
    "tag": "12835604(US)29",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2524272725"
  }, // 2G-D
  "HEATH HOUSE PRIDE 8TH": {
    "name": "HEATH HOUSE PRIDE 8TH",
    "tag": "HLD.W3.22",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B26592F2F25"
  }, // 1G-D
  "RIBBLE EQUIDORA G059": {
    "name": "RIBBLE EQUIDORA G059",
    "tag": "UK 182746 201059",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262525582126",
    "sire": "FORDEL DOMINANT D576",
    "dam": "RIBBLE EQUIDORA C415"
  }, // 2G-S
  "FORDEL DOMINANT D576": {
    "name": "FORDEL DOMINANT D576",
    "tag": "UK 542511 200576",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A252127232E"
  }, // 2G-D
  "RIBBLE EQUIDORA C415": {
    "name": "RIBBLE EQUIDORA C415",
    "tag": "UK 182746 200415",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B21232F2323"
  }, // Bull
  "Warson Esteemed U166": {
    "name": "Warson Esteemed U166",
    "tag": "UK 375444 100166",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B582525262F&9=525C5A5B",
    "sire": "HELE BARTON ECLIPSE P242",
    "dam": "RIBBLE ELKA R984"
  }, // Sire
  "HELE BARTON ECLIPSE P242": {
    "name": "HELE BARTON ECLIPSE P242",
    "tag": "UK 382172 401242",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B245823262023",
    "sire": "ARDROSSAN ADMIRAL A2 (AI) (IMP)",
    "dam": "HELE BARTON EMPRESS J661"
  }, // 1G-S
  "ARDROSSAN ADMIRAL A2 (AI) (IMP)": {
    "name": "ARDROSSAN ADMIRAL A2 (AI) (IMP)",
    "tag": "NAQA2(AU)33",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262427252121",
    "sire": "ARDROSSAN DIRECTION W109",
    "dam": "KENNY'S CREEK ROSEBUD W171"
  }, // 2G-S
  "ARDROSSAN DIRECTION W109": {
    "name": "ARDROSSAN DIRECTION W109",
    "tag": "NAQW109(AU)",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262427252122"
  }, // 2G-D
  "KENNY'S CREEK ROSEBUD W171": {
    "name": "KENNY'S CREEK ROSEBUD W171",
    "tag": "NDIW171(AU)",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26242725222E"
  }, // 1G-D
  "HELE BARTON EMPRESS J661": {
    "name": "HELE BARTON EMPRESS J661",
    "tag": "UK 382172 400661",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262226212E25",
    "sire": "BLACKHAUGH LORD HOYD G698",
    "dam": "HELE BARTON EMPRESS G378"
  }, // 2G-S
  "BLACKHAUGH LORD HOYD G698": {
    "name": "BLACKHAUGH LORD HOYD G698",
    "tag": "UK 562121 600698",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262626272E20"
  }, // 2G-D
  "HELE BARTON EMPRESS G378": {
    "name": "HELE BARTON EMPRESS G378",
    "tag": "UK 382172 100378",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26252526232F"
  }, // Dire
    "RIBBLE ELKA R984": {
    "name": "RIBBLE ELKA R984",
    "tag": "UK 182746 301984",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242327582F24",
    "sire": "BLELACK LORD HANCOCK K453",
    "dam": "RIBBLE ELKA L556"
  }, // 1G-S
  "BLELACK LORD HANCOCK K453": {
    "name": "BLELACK LORD HANCOCK K453",
    "tag": "UK 521115 402453",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262122582E2E",
    "sire": "NETHERALLAN PETER PERSHORE E052",
    "dam": "BLELACK LADY HEATHER D977"
  }, // 2G-S
  "NETHERALLAN PETER PERSHORE E052": {
    "name": "NETHERALLAN PETER PERSHORE E052",
    "tag": "UK 541616 100052",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923252526"
  }, // 2G-D
  "BLELACK LADY HEATHER D977": {
    "name": "BLELACK LADY HEATHER D977",
    "tag": "UK 521115 500977",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2522222F20"
  }, // 1G-D
  "RIBBLE ELKA L556": {
    "name": "RIBBLE ELKA L556",
    "tag": "UK 182746 201556",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255921202223",
    "sire": "FORDEL DOMINANT D576",
    "dam": "RIBBLE ELKA F806"
  }, // 2G-S
  "FORDEL DOMINANT D576": {
    "name": "FORDEL DOMINANT D576",
    "tag": "UK 542511 200576",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A252127232E"
  }, // 2G-D
  "RIBBLE ELKA F806": {
    "name": "RIBBLE ELKA F806",
    "tag": "UK 182746 100806",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265823222121"
  }, // Bull
  "WARSON ELIAS U226": {
    "name": "WARSON ELIAS U226",
    "tag": "UK 373831 400226",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B2658262E20&9=525C5A59",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "RAWBURN ELLEN ERICA R075"
  }, // Dire
    "RAWBURN ELLEN ERICA R075": {
    "name": "RAWBURN ELLEN ERICA R075",
    "tag": "UK 562106 602075",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242423222520",
    "sire": "SKAILL EDDIE L434",
    "dam": "RAWBURN ELLEN ERICA G669"
  }, // 1G-S
  "SKAILL EDDIE L434": {
    "name": "SKAILL EDDIE L434",
    "tag": "UK 523853 402434",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B25592425262D",
    "sire": "NIGHTINGALE PROUD JAKE G405",
    "dam": ""
  }, // 2G-S
  "NIGHTINGALE PROUD JAKE G405": {
    "name": "NIGHTINGALE PROUD JAKE G405",
    "tag": "UK 302307 400405",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262622232E22"
  }, // 2G-D
  "SKAILL ESTELLA F512": {
    "name": "SKAILL ESTELLA F512",
    "tag": "UK 523853 601512",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262759212723"
  }, // 1G-D
  "RAWBURN ELLEN ERICA G669": {
    "name": "RAWBURN ELLEN ERICA G669",
    "tag": "UK 562106 700669",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26265823202F",
    "sire": "RAWBURN DUKE WINDSOR E440",
    "dam": "RAWBURN ELLEN ERICA E419"
  }, // 2G-S
  "RAWBURN DUKE WINDSOR E440": {
    "name": "RAWBURN DUKE WINDSOR E440",
    "tag": "UK 562106 200440",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265959262026"
  }, // 2G-D
  "RAWBURN ELLEN ERICA E419": {
    "name": "RAWBURN ELLEN ERICA E419",
    "tag": "UK 562106 200419",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265959252524"
  }, // Bull
  "WARSON ELGAR U168": {
    "name": "WARSON ELGAR U168",
    "tag": "UK 373831 200168",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5A21252420&9=525C595F",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "WARRENHO ELVA M411 "
  }, // Dire
    "WARRENHO ELVA M411": {
    "name": "WARRENHO ELVA M411",
    "tag": "UK 280309 400411",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B25272421242D",
    "sire": "WARRENHO DALMIGAVIE G237",
    "dam": "WARRENHO ELDERBERRY H259"
  }, // 1G-S
  "WARRENHO DALMIGAVIE G237": {
    "name": "WARRENHO DALMIGAVIE G237",
    "tag": "UK 280309 500237",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262527582720",
    "sire": "NIGHTINGALE DALMIGAVIE Y195",
    "dam": "TRULLINGHAM DELIXIR D001"
  }, // 2G-S
  "NIGHTINGALE DALMIGAVIE Y195": {
    "name": "NIGHTINGALE DALMIGAVIE Y195",
    "tag": "UK WE049700195",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2720232423"
  }, // 2G-D
  "TRULLINGHAM DELIXIR D001": {
    "name": "TRULLINGHAM DELIXIR D001",
    "tag": "UK 287388 300001",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2526242325"
  }, // 1G-D
  "WARRENHO ELDERBERRY H259": {
    "name": "WARRENHO ELDERBERRY H259",
    "tag": "UK 280309 600259",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262426202424",
    "sire": "LOCKERLEY LEGEND F612",
    "dam": "WARRENHO ELVA B068"
  }, // 2G-S
  "LOCKERLEY LEGEND F612": {
    "name": "LOCKERLEY LEGEND F612",
    "tag": "UK 282556 200612",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2658272F2F25"
  }, // 2G-D
  "WARRENHO ELVA B068": {
    "name": "WARRENHO ELVA B068",
    "tag": "UK 280309 400068",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5A59232623"
  }, // Bull
  "WARSON JODARMI U167": {
    "name": "WARSON JODARMI U167",
    "tag": "UK 373831 100167",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5A21252421&9=525C595A",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "WARRENHO JODY S612"
  }, // Dire
    "WARRENHO JODY S612": {
    "name": "WARRENHO JODY S612",
    "tag": "UK 280309 200612",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B235A2227262F",
    "sire": "WARRENHO FABIO M432",
    "dam": "WARRENHO JODY N454"
  }, // 1G-S
  "WARRENHO FABIO M432": {
    "name": "WARRENHO FABIO M432",
    "tag": "UK 280309 400432",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252659242021",
    "sire": "HF EL TIGRE 28U (AI) (IMP)",
    "dam": "NETHERTON FLEUR G413"
  }, // 2G-S
  "HF EL TIGRE 28U (AI) (IMP)": {
    "name": "HF EL TIGRE 28U (AI) (IMP)",
    "tag": "1469322(CA)34",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262159262522"
  }, // 2G-D
  "NETHERTON FLEUR G413": {
    "name": "NETHERTON FLEUR G413",
    "tag": "UK 542697 600413",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262721242221"
  }, // 1G-D
  "WARRENHO JODY N454": {
    "name": "WARRENHO JODY N454",
    "tag": "UK 280309 500454",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252327202722",
    "sire": "AULDHOUSEBURN KARLOS D218",
    "dam": "DUNCANZIEMERE JODY Y280"
  }, // 2G-S
  "AULDHOUSEBURN KARLOS D218": {
    "name": "AULDHOUSEBURN KARLOS D218",
    "tag": "UK 580271 400218",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265A59262E2D"
  }, // 2G-D
  "DUNCANZIEMERE JODY Y280": {
    "name": "DUNCANZIEMERE JODY Y280",
    "tag": "UK AA044000280",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2759582521"
  }, //Bull
  "WARSON PROMETHEUS U189": {
    "name": "WARSON PROMETHEUS U189",
    "tag": "UK 373831 200189",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B592422212E&9=525C5859",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "BOREWELL PRUDENCE S892"
  }, // Dire
    "BOREWELL PRUDENCE S892": {
    "name": "BOREWELL PRUDENCE S892",
    "tag": "UK 106816 500892",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2359582F2721",
    "sire": "NIGHTINGALE PERRY L577",
    "dam": "BOREWELL PRUDENCE K416"
  }, // 1G-S
  "NIGHTINGALE PERRY L577": {
    "name": "NIGHTINGALE PERRY L577",
    "tag": "UK 302307 100577",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255A2026212F",
    "sire": "NIGHTINGALE PLOUGHMAN G376",
    "dam": "NIGHTINGALE PRIDE PERSHORE G406"
  }, // 2G-S
  "NIGHTINGALE PLOUGHMAN G376": {
    "name": "NIGHTINGALE PLOUGHMAN G376",
    "tag": "UK 302307 300376",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262659582E2D"
  }, // 2G-D
  "NIGHTINGALE PRIDE PERSHORE G406": {
    "name": "NIGHTINGALE PRIDE PERSHORE G406",
    "tag": "UK 302307 500406",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2625592F262D"
  }, // 1G-D
  "BOREWELL PRUDENCE K416": {
    "name": "BOREWELL PRUDENCE K416",
    "tag": "UK 106816 500416",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26212323272D",
    "sire": "BOREWELL NECTAR E096",
    "dam": "WEDDERLIE PRUDENCE Y988"
  }, // 2G-S
  "BOREWELL NECTAR E096": {
    "name": "BOREWELL NECTAR E096",
    "tag": "UK 106816 700096",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923582225"
  }, // 2G-D
  "WEDDERLIE PRUDENCE Y988": {
    "name": "WEDDERLIE PRUDENCE Y988",
    "tag": "UK BE059900988",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2758202224"
  }, // Bull
  "WARSON BLACK PHINEAS U148": {
    "name": "WARSON BLACK PHINEAS U148",
    "tag": "UK 375444 400148",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5924222024&9=525B515F",
    "sire": "OAKCHURCH DUSTER M109 #UK313622 300109",
    "dam": "WARSON BLACK PHOEBE S078"
  }, // Dire
    "WARSON BLACK PHOEBE S078": {
    "name": "WARSON BLACK PHOEBE S078",
    "tag": "UK 375444 400078",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B232658272524",
    "sire": "LELACK LORD HANCOCK K453",
    "dam": "RIBBLE BLACK BELINDA L553"
  }, // 1G-S
  "LELACK LORD HANCOCK K453": {
    "name": "LELACK LORD HANCOCK K453",
    "tag": "UK 521115 402453",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262122582E2E",
    "sire": "NETHERALLAN PETER PERSHORE E052",
    "dam": "BLELACK LADY HEATHER D977"
  }, // 2G-S
  "NETHERALLAN PETER PERSHORE E052": {
    "name": "NETHERALLAN PETER PERSHORE E052",
    "tag": "UK 541616 100052",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923252526"
  }, // 2G-D
  "BLELACK LADY HEATHER D977": {
    "name": "BLELACK LADY HEATHER D977",
    "tag": "UK 521115 500977",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A2522222F20"
  }, // 1G-D
  "RIBBLE BLACK BELINDA L553": {
    "name": "RIBBLE BLACK BELINDA L553",
    "tag": "UK 182746 601553",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2559212F242F",
    "sire": "RAWBURN ELYSIUM F547",
    "dam": "RIBBLE BLACK BELINDA C323"
  }, // 2G-S
  "RAWBURN ELYSIUM F547": {
    "name": "RAWBURN ELYSIUM F547",
    "tag": "UK 562106 400547",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2658252F2225"
  }, // 2G-D
  "RIBBLE BLACK BELINDA C323": {
    "name": "RIBBLE BLACK BELINDA C323",
    "tag": "UK 182746 100323",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B21232F272E"
  }, //Bull
  "WARSON ERROLL U204": {
    "name": "WARSON ERROLL U204",
    "tag": "UK 373831 300204",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5825262E2E&9=525B515E",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "SUNOAK ERICA R231"
  }, // Dire
    "SUNOAK ERICA R231": {
    "name": "SUNOAK ERICA R231",
    "tag": "UK 263127 700231",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242520202421",
    "sire": "WARRENHO RAZZLE M446",
    "dam": "SUNOAK ERICA J087"
  }, // 1G-S
  "WARRENHO RAZZLE M446": {
    "name": "WARRENHO RAZZLE M446",
    "tag": "UK 280309 400446",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2525242F202D",
    "sire": "ANKONIAN ELIXIR 100 (AI) (IMP)",
    "dam": "NETHERTON RACHEL C227"
  }, // 2G-S
  "ANKONIAN ELIXIR 100 (AI) (IMP)": {
    "name": "ANKONIAN ELIXIR 100 (AI) (IMP)",
    "tag": "12758219(US)23",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5A59222124"
  }, // 2G-D
  "NETHERTON RACHEL C227": {
    "name": "NETHERTON RACHEL C227",
    "tag": "UK 542697 200227",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5920262F25"
  }, // 1G-D
  "SUNOAK ERICA J087": {
    "name": "SUNOAK ERICA J087",
    "tag": "UK 263127 300087",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262327232321",
    "sire": "ROSEMEAD BAGSHOTT E614",
    "dam": "SUNOAK ERICA E029"
  }, // 2G-S
  "ROSEMEAD BAGSHOTT E614": {
    "name": "ROSEMEAD BAGSHOTT E614",
    "tag": "UK 261902 500614",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265920242F26"
  }, // 2G-D
  "SUNOAK ERICA E029": {
    "name": "SUNOAK ERICA E029",
    "tag": "UK 263127 100029",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923202520"
  }, // Bull
  "ARSON MASTER BRYANT U212": {
    "name": "ARSON MASTER BRYANT U212",
    "tag": "UK 373831 400212",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5825252625&9=525B5159",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "ROSEMEAD BRIGHT MAID R674"
  }, // Dire
    "ROSEMEAD BRIGHT MAID R674": {
    "name": "ROSEMEAD BRIGHT MAID R674",
    "tag": "UK 261902 101674",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242327232326",
    "sire": "LOCKERLEY BLACK BOROMIR K173",
    "dam": "ROSEMEAD BRIGHT MAID G771"
  }, // 1G-S
  "LOCKERLEY BLACK BOROMIR K173": {
    "name": "LOCKERLEY BLACK BOROMIR K173",
    "tag": "UK 282556 301173",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262124242621",
    "sire": "RAWBURN ROMMEL E423",
    "dam": "LOCKERLEY BLACK BUTTERFLY E510"
  }, // 2G-S
  "RAWBURN ROMMEL E423": {
    "name": "RAWBURN ROMMEL E423",
    "tag": "UK 562106 600423",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265959252522"
  }, // 2G-D
  "LOCKERLEY BLACK BUTTERFLY E510": {
    "name": "LOCKERLEY BLACK BUTTERFLY E510",
    "tag": "UK 282556 500510",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265A2058222D"
  }, // 1G-D
  "ROSEMEAD BRIGHT MAID G771": {
    "name": "ROSEMEAD BRIGHT MAID G771",
    "tag": "UK 261902 100771",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262625242423",
    "sire": "ELLIN ERRONEOUS C570",
    "dam": "ROSEMEAD BRIGHT MAID C389"
  }, // 2G-S
  "ELLIN ERRONEOUS C570": {
    "name": "ELLIN ERRONEOUS C570",
    "tag": "UK 560308 400570",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5858262F20"
  }, // 2G-D
  "ROSEMEAD BRIGHT MAID C389": {
    "name": "ROSEMEAD BRIGHT MAID C389",
    "tag": "UK 261902 400389",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B2120202526"
  }, // Bull
  "WARSON ERIC U234": {
    "name": "WARSON ERIC U234",
    "tag": "UK 373831 500234",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B265825252D&9=525B5127",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "SUNOAK ERICA R217"
  }, // Dire
    "SUNOAK ERICA R217": {
    "name": "SUNOAK ERICA R217",
    "tag": "UK 263127 700217",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242521222221",
    "sire": "WARRENHO RAZZLE M446",
    "dam": "SUNOAK ERICA M162"
  }, // 1G-S
  "WARRENHO RAZZLE M446": {
    "name": "WARRENHO RAZZLE M446",
    "tag": "UK 280309 400446",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2525242F202D",
    "sire": "ANKONIAN ELIXIR 100 (AI) (IMP)",
    "dam": "NETHERTON RACHEL C227"
  }, // 2G-S
  "ANKONIAN ELIXIR 100 (AI) (IMP)": {
    "name": "ANKONIAN ELIXIR 100 (AI) (IMP)",
    "tag": "12758219(US)23",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5A59222124"
  }, // 2G-D
  "NETHERTON RACHEL C227": {
    "name": "NETHERTON RACHEL C227",
    "tag": "UK 542697 200227",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5920262F25"
  }, // 1G-D
  "SUNOAK ERICA M162": {
    "name": "SUNOAK ERICA M162",
    "tag": "UK 263127 100162",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B255820202020",
    "sire": "ROSEMEAD BOLD MAN J980",
    "dam": "SUNOAK ERICA E029"
  }, // 2G-S
  "ROSEMEAD BOLD MAN J980": {
    "name": "ROSEMEAD BOLD MAN J980",
    "tag": "UK 261902 700980",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262226212E26"
  }, // 2G-D
  "SUNOAK ERICA E029": {
    "name": "SUNOAK ERICA E029",
    "tag": "UK 263127 100029",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265923202520"
  }, // Bull
  "WARSON KALIKO U169": {
    "name": "WARSON KALIKO U169",
    "tag": "UK 373831 300169",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5A2125242F&9=525A5B50",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "BOREWELL KATRINA S868"
  }, // Dire
    "BOREWELL KATRINA S868": {
    "name": "BOREWELL KATRINA S868",
    "tag": "UK 106816 200868",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B235A21222026",
    "sire": "BOSULLOW BLACKMAN J247",
    "dam": "BOREWELL KATRINE J329"
  }, // 1G-S
  "BOSULLOW BLACKMAN J247": {
    "name": "BOSULLOW BLACKMAN J247",
    "tag": "UK 383807 200247",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262326262F2E",
    "sire": "BOSULLOW ELMARK G201",
    "dam": "BOSULLOW BLACKBIRD C094"
  }, // 2G-S
  "BOSULLOW ELMARK G201": {
    "name": "BOSULLOW ELMARK G201",
    "tag": "UK 383807 500201",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26262623232E"
  }, // 2G-D
  "BOSULLOW BLACKBIRD C094": {
    "name": "BOSULLOW BLACKBIRD C094",
    "tag": "UK 383807 300094",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5A212F2F2F"
  }, // 1G-D
  "BOREWELL KATRINE J329": {
    "name": "BOREWELL KATRINE J329",
    "tag": "UK 106816 200329",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262323582520",
    "sire": "SUMMERHILL BAROLA G361",
    "dam": "WEDDERLIE KATRINA Y070"
  }, // 2G-S
  "SUMMERHILL BAROLA G361": {
    "name": "SUMMERHILL BAROLA G361",
    "tag": "UK 302307 200361",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2627212F2526"
  }, // 2G-D
  "WEDDERLIE KATRINA Y070": {
    "name": "WEDDERLIE KATRINA Y070",
    "tag": "UK BE059901070",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A27582F2426"
  }, // Bull
  "WARSON EUSTACE U179": {
    "name": "WARSON EUSTACE U179",
    "tag": "UK 373831 600179",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B592422222E&9=525A5950",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "BOREWELL EVELYN S881"
  }, // Dire
    "BOREWELL EVELYN S881": {
    "name": "BOREWELL EVELYN S881",
    "tag": "UK 106816 100881",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B235958202E2D",
    "sire": "NIGHTINGALE PARSON M667",
    "dam": "BOREWELL EVELYN M552"
  }, // 1G-S
  "NIGHTINGALE PARSON M667": {
    "name": "NIGHTINGALE PARSON M667",
    "tag": "UK 302307 700667",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252558202F22",
    "sire": "NIGHTINGALE POUNDMAN C183",
    "dam": "NIGHTINGALE PRECIOUS PRINCESS H411"
  }, // 2G-S
  "NIGHTINGALE POUNDMAN C183": {
    "name": "NIGHTINGALE POUNDMAN C183",
    "tag": "UK 302307 600183",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A5A272F252E"
  }, // 2G-D
  "NIGHTINGALE PRECIOUS PRINCESS H411": {
    "name": "NIGHTINGALE PRECIOUS PRINCESS H411",
    "tag": "UK 302307 300411",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262524242622"
  }, // 1G-D
  "BOREWELL EVELYN M552": {
    "name": "BOREWELL EVELYN M552",
    "tag": "UK 106816 100552",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252622242726",
    "sire": "BOSULLOW BLACKMAN J247",
    "dam": "BOREWELL EVELYN J369"
  }, // 2G-S
  "BOSULLOW BLACKMAN J247": {
    "name": "BOSULLOW BLACKMAN J247",
    "tag": "UK 383807 200247",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262326262F2E"
  }, // 2G-D
  "BOREWELL EVELYN J369": {
    "name": "BOREWELL EVELYN J369",
    "tag": "UK 106816 700369",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262323582325"
  }, // Bull
  "WARSON JULIUS ERIC U173": {
    "name": "WARSON JULIUS ERIC U173",
    "tag": "UK 373831 700173",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5A21202722&9=525A595B",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "WARRENHO JULIET ERICA S635"
  }, // Dire
    "WARRENHO JULIET ERICA S635": {
    "name": "WARRENHO JULIET ERICA S635",
    "tag": "UK 280309 400635",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B232727232623",
    "sire": "WARRENHO FABIO M432",
    "dam": "WARRENHO JULIET ERICA J273"
  }, // 1G-S
  "WARRENHO FABIO M432": {
    "name": "WARRENHO FABIO M432",
    "tag": "UK 280309 400432",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252659242021",
    "sire": "HF EL TIGRE 28U (AI) (IMP)",
    "dam": "NETHERTON FLEUR G413"
  }, // 2G-S
  "HF EL TIGRE 28U (AI) (IMP)": {
    "name": "HF EL TIGRE 28U (AI) (IMP)",
    "tag": "1469322(CA)34",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262159262522"
  }, // 2G-D
  "NETHERTON FLEUR G413": {
    "name": "NETHERTON FLEUR G413",
    "tag": "UK 542697 600413",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262721242221"
  }, // 1G-D
  "WARRENHO JULIET ERICA J273": {
    "name": "WARRENHO JULIET ERICA J273",
    "tag": "UK 280309 600273",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262326252523",
    "sire": "LOCKERLEY LEGEND F612",
    "dam": "WARRENHO JULIET ERICA F186"
  }, // 2G-S
  "LOCKERLEY LEGEND F612": {
    "name": "LOCKERLEY LEGEND F612",
    "tag": "UK 282556 200612",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2658272F2F25"
  }, // 2G-D
  "WARRENHO JULIET ERICA F186": {
    "name": "WARRENHO JULIET ERICA F186",
    "tag": "UK 280309 300186",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265822272723"
  }, // Bull
  "WARSON JAGER ERIC U205": {
    "name": "WARSON JAGER ERIC U205",
    "tag": "UK 373831 400205",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5A5B5825262E2D&9=525A585A",
    "sire": "RAWBURN JAGGER ERIC R094",
    "dam": "WARRENHO JULIET ERICA S632"
  }, // Dire
    "WARRENHO JULIET ERICA S632": {
    "name": "WARRENHO JULIET ERICA S632",
    "tag": "UK 280309 100632",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B232758242F23",
    "sire": "WARRENHO FABIO M432",
    "dam": "WARRENHO JULIET ERICA F189"
  }, // 1G-S
  "WARRENHO FABIO M432": {
    "name": "WARRENHO FABIO M432",
    "tag": "UK 280309 400432",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252659242021",
    "sire": "HF EL TIGRE 28U (AI) (IMP)",
    "dam": "NETHERTON FLEUR G413"
  }, // 2G-S
  "HF EL TIGRE 28U (AI) (IMP)": {
    "name": "HF EL TIGRE 28U (AI) (IMP)",
    "tag": "1469322(CA)34",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262159262522"
  }, // 2G-D
  "NETHERTON FLEUR G413": {
    "name": "NETHERTON FLEUR G413",
    "tag": "UK 542697 600413",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262721242221"
  }, // 1G-D
  "WARRENHO JULIET ERICA F189": {
    "name": "WARRENHO JULIET ERICA F189",
    "tag": "UK 280309 600189",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B265822272721",
    "sire": "NETHERTON KIWI SAM A141",
    "dam": "JULIET ERICA OF ANGLING SPRING"
  }, // 2G-S
  "NETHERTON KIWI SAM A141": {
    "name": "NETHERTON KIWI SAM A141",
    "tag": "UK 542697 700141",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A592024272D"
  }, // 2G-D
  "JULIET ERICA OF ANGLING SPRING": {
    "name": "JULIET ERICA OF ANGLING SPRING",
    "tag": "TAS.X3.23",
    "ebvLink": "http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B2220212525"
  }
};

const getAnimal = name => {
  if (typeof name !== 'string') {
    console.log('name is ' + JSON.stringify(name, null, 2));
    return null;
  }
  if (db[name]) return db[name];
  if (db[name.toUpperCase()]) return db[name.toUpperCase()];
  return null;
};

const addParents = (sireName, damName, depth, baseAnimal = null) => {
  const ancestors = {};
  let sire = getAnimal(sireName);
  if (sire) {
    ancestors.sire = { ...sire };
  } else {
    throw new Error('missing sire "' + sireName + '" ancestor of ' + baseAnimal);
  }
  let dam = getAnimal(damName);
  if (dam) {
    ancestors.dam = { ...dam };
  } else {
    throw new Error('missing dam "' + damName + '" ancestor of ' + baseAnimal);
  }
  if (depth < 3) {
    ancestors.sire.ancestors = addParents(sire.sire, sire.dam, depth + 1, baseAnimal);
    ancestors.dam.ancestors = addParents(dam.sire, dam.dam, depth + 1, baseAnimal);
    delete ancestors.sire.sire;
    delete ancestors.sire.dam;
    delete ancestors.dam.sire;
    delete ancestors.dam.dam;
  }
  return ancestors;
};

const buildAncestors = row => {
  try {
    return addParents(row['Sire'], row['Dam'], 1, row['Name']);
  } catch (error) {
    console.log(error.toString());
    return null;
  }
};

export default buildAncestors;
