import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import './admin-form-field.scss';

class NumberField extends Component {
  constructor (props) {
    super(props);
    const value = props.value || props.value === 0 ? props.value : '';
    this.state = {
      inFocus: false,
      inputText: value,
      value
    };
    this.inputRef = createRef();
  }
  static getDerivedStateFromProps (props, state) {
    if (!state.inFocus) {
      return { ...state, value: props.value, inputText: '' + props.value };
    }
    return state;
  }
  handleChange (event) {
    const inputText = event.target.value;
    const value = parseFloat(event.target.value);
    if (typeof value === 'number') {
      this.setState({ value, inputText });
    } else {
      this.setState({ inputText });
    }
  }
  handleFocus () {
    this.setState({ inFocus: true });
  }
  handleBlur () {
    if (typeof this.state.value === 'number' && !isNaN(this.state.value)) {
      this.props.update(this.state.value);
    }
    this.setState({ inFocus: false });
  }
  getError () {
    return !this.state.inFocus && this.props.error ?
      this.props.error : null;
  }
  render () {
    let labelClass = 'form-label';
    if (this.getError()) labelClass += ' error';
    return (
      <div className="form-field-wrapper">
        <label className={ labelClass }>
          { this.props.name }
        </label>
        <input
          className='form-text-box'
          data-cy={ this.props.testId }
          type='number'
          value={ this.state.inputText }
          onChange={ this.handleChange.bind(this) }
          ref={ this.inputRef }
          onFocus={ this.handleFocus.bind(this) }
          onBlur={ this.handleBlur.bind(this) } />
        <div className="field-error-text">{ this.getError() }</div>
      </div>
    );
  }
}

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  error: PropTypes.string,
  update: PropTypes.func,
  testId: PropTypes.string
};

export default NumberField;
