import React from 'react';
import PropTypes from 'prop-types';
import './checkbox-and-label.scss';

const CheckboxAndLabel = ({ label, isChecked, toggle }) => {
  return (
    <div onClick={ toggle } className='bb-checkbox-component'>
      <div className="checkbox" data-cy={ `${label}-checkbox`}>
        { isChecked ? '✓' : ''}
      </div>
      <p className='label'>{ label }</p>
    </div>
  );
};

CheckboxAndLabel.propTypes = ({
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
});

export default CheckboxAndLabel;
