
import mongoid from 'mongoid-js';
import buildAncestors from './buildAncestors';

/* eslint-disable max-len */

const parsePrice = priceString => {
  try {
    return parseInt(priceString.replace('£', '').replace(',', ''));
  } catch (error) {
    return 0;
  }
};

const parseNumber = numberStr => {
  try {
    let result = null;
    if (numberStr.includes('.')) {
      result = Number.parseFloat(numberStr);
    }
    result = parseInt(numberStr);
    if (Number.isNaN(result)) return null;
    return result;
  } catch (error) {
    return null;
  }
};

const parseDate = dateStr => {
  const year = dateStr.substring(6, 10);
  const month = parseInt(dateStr.substring(3, 5)) - 1;
  const day = dateStr.substring(0, 2);
  return new Date(year, month, day);
};

const offeringFromCsvRow = row => {
  let offering = {
    id: mongoid(),
    name: row['Name'] || null,
    tag: row['Tag No'] || null,
    type: row['Type'] || null,
    breed: row['Breed Name'] || null,
    location: 'Devon',
    // AHDB: {
    //   src: 'https://s3.eu-west-2.amazonaws.com/media.beefbook.com/5c541d58d27734562c1aea51/AHDB.pdf'
    // },
    // BCVA: {
    //   src: 'https://s3.eu-west-2.amazonaws.com/media.beefbook.com/5c541d58d27734562c1aea51/BCVA.pdf'
    // },
    // price: getPrice(row),
    price: parsePrice(row['price']),
    // dob: row['DoB'] || null,
    birthDate: parseDate(row['DoB']),
    weight: row['Current Live Weight'] || null,
    birthWeight: parseNumber(row['Birth Weight']),
    dailyGain: parseNumber(row['Avg Daily Live Kg Gain']),
    calvingEase: row['Calving Ease'] || null,
    EBV: {
      TIscore: parseNumber(row['TI Score']),
      SRIscore: parseNumber(row['SRI Score']),
      rank: row['Combined Rank'] || null
    },
    // beefBook: {
    //   docility: 1,
    //   milkiness: 58
    // },
    // video: {
    //   src: 'https://s3.eu-west-2.amazonaws.com/media.beefbook.com/off-halter.m4v',
    //   poster: 'https://s3.eu-west-2.amazonaws.com/media.beefbook.com/5c541d58d27734562c1aea51/poster1.png',
    //   date: '06/03/2019',
    //   timestamps: {
    //     views: [
    //       { timestamp: 1.6, label: 'front', duration: 2 },
    //       { timestamp: 4.3, label: 'fertility', duration: 2 },
    //       { timestamp: 7, label: 'side', duration: 2 },
    //       { timestamp: 13, label: 'structure', duration: 2 }
    //     ],
    //     straplines: [
    //       { timestamp: 1.5, label: 'shows docility with the farmer', duration: 3 },
    //       { timestamp: 6, label: 'good locomotion', duration: 4 },
    //       { timestamp: 11, label: 'high docility', duration: 3 },
    //       { timestamp: 13.3, label: 'great structure', duration: 4 }
    //     ]
    //   }
    // },
    badges: [
      'Pasture For Life Certified'
    ],
    // sire: row['Sire'] || null,
    // dam: row['Dam'] || null,
    ancestors: buildAncestors(row),
    // ancestors: {
    //   sire: {
    //     name: 'Netherton Americano M703',
    //     tag: 'UK 542697 200703',
    //     ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B252423212020&9=5E505A5E',
    //     ancestors: {
    //       sire: {
    //         name: 'NETHERTON MR RADER J527',
    //         tag: 'UK542697 100527',
    //         ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B2622582F2F2F',
    //         ancestors: {
    //           sire: {
    //             name: 'DYLEMMA RADAR W42',
    //             tag: 'CBJW42(AU)31',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262720272E20'
    //           },
    //           dam: {
    //             name: 'NETHERTON MISSIE A114',
    //             tag: 'UK 542697 100114',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5A592026242F'
    //           }
    //         }
    //       },
    //       dam: {
    //         name: 'NETHERTON ANNIE H474',
    //         tag: 'UK 542697 400474',
    //         ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262427252224',
    //         ancestors: {
    //           sire: {
    //             name: 'TC GRID TOPPER 355',
    //             tag: '14526645(US)32',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262558232722'
    //           },
    //           dam: {
    //             name: 'SOUTHLAND AWESTRUCK 117R',
    //             tag: '#1295111(CA)32',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262558232726'
    //           }
    //         }
    //       }
    //     }
    //   },
    //   dam: {
    //     name: 'Standlynch Fine Girl P797',
    //     tag: 'UK 323963 100797',
    //     ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B242523272126&9=5E50585F',
    //     ancestors: {
    //       sire: {
    //         name: 'BOREWELL MISTER ECCLES L462',
    //         tag: 'UK 106816 200462',
    //         ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B25595821232E',
    //         ancestors: {
    //           sire: {
    //             name: 'B/R NEW FRONTIER 095',
    //             tag: '13588640(US)28',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B5B5A59212026'
    //           },
    //           dam: {
    //             name: 'ANKONY MISS EFFIE 8433',
    //             tag: '16060002(US)34',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B26215827252E'
    //           }
    //         }
    //       },
    //       dam: {
    //         name: 'STANDLYNCH FINE GIRL L484',
    //         tag: 'UK 323963 300484',
    //         ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B25592225202E',
    //         ancestors: {
    //           sire: {
    //             name: 'BLACKHAUGH EVERY TIME H842',
    //             tag: 'UK 562121 300842',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262426232725'
    //           },
    //           dam: {
    //             name: 'STANDLYNCH FINE GIRL H267',
    //             tag: 'UK 323963 300267',
    //             ebvLink: 'http://abri.une.edu.au/online/cgi-bin/i4.dll?1=3E37202F&2=2420&3=56&5=2B3C2B3C3A&6=275D5B262522222523'
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    production: {
      // qualityIndex: 8,
      bullFertility: {
        score: getScore(row, 'Fertility'),
        supportingInfo: [
          // { type: 'download', title: 'BCVA Bull Pre-Breeding Examination Certificate', url: 'https://s3.eu-west-2.amazonaws.com/media.beefbook.com/5c541d58d27734562c1aea51/BCVA.pdf' }
        ]
      },
      calvingEase: {
        score: getScore(row, 'Calving Ease'),
        supportingInfo: []
      },
      growthEfficiency: {
        score: getScore(row, 'Growth Efficiency'),
        supportingInfo: []
      },
      temperament: {
        score: getScore(row, 'Temperament'),
        supportingInfo: []
      },
      structure: {
        score: getScore(row, 'Structure'),
        supportingInfo: []
      },
      size: {
        score: getScore(row, 'Animal Size (Cow & Calf)'),
        supportingInfo: []
      },
      welfare: {
        score: getScore(row, 'Welfare'),
        supportingInfo: []
      },
      health: {
        score: getScore(row, 'Health'),
        supportingInfo: []
      },
      medication: {
        score: getScore(row, 'Medication'),
        supportingInfo: []
      }
    },
    eating: {
      // qualityIndex: 5,
      breed: {
        score: getScore(row, 'Breed'),
        supportingInfo: []
      },
      homeBred: {
        score: getScore(row, 'Home Bred'),
        supportingInfo: []
      },
      pastureFed: {
        score: getScore(row, 'Pasture Fed'),
        supportingInfo: []
      },
      flavour: {
        score: getScore(row, 'Flavour & Taste'),
        supportingInfo: []
      },
      texture: {
        score: getScore(row, 'Texture & Tenderness'),
        supportingInfo: []
      },
      fatCover: {
        score: getScore(row, 'Fat Cover and Colour'),
        supportingInfo: []
      },
      pgiStatus: {
        score: getScore(row, 'West Country Beef PGI status'),
        supportingInfo: []
      },
      nutritionalValue: {
        score: getScore(row, 'Nutritional Value'),
        supportingInfo: []
      },
      retailValue: {
        score: getScore(row, 'Retail Value'),
        supportingInfo: []
      }
    },
    environment: {
      // qualityIndex: 5,
      carbonSequestation: {
        score: getScore(row, 'Carbon Sequestration'),
        supportingInfo: []
      },
      methaneMitigation: {
        score: getScore(row, 'Methane Mitigation'),
        supportingInfo: []
      },
      croppingGhgReduction: {
        score: getScore(row, 'Cropping for GHG Reduction'),
        supportingInfo: []
      },
      soilCondition: {
        score: getScore(row, 'Soil Condition'),
        supportingInfo: []
      },
      traceElements: {
        score: getScore(row, 'Trace Elements and Minerals Levels'),
        supportingInfo: []
      },
      plantVarieties: {
        score: getScore(row, 'Pasture Plant varieties'),
        supportingInfo: []
      },
      pastureVsGrain: {
        score: getScore(row, 'Pasture vs Grain and Soya'),
        supportingInfo: []
      },
      antibiotics: {
        score: getScore(row, 'Medication Antibiotics'),
        supportingInfo: []
      },
      waterQuality: {
        score: getScore(row, 'Water Quality'),
        supportingInfo: []
      }
    }
  };
  return offering;
};

const getScore = (row, column) => {
  try {
    let score = parseInt(row[column]);
    if (column === 'Carbon Sequestration' ||
    column === 'Nutritional Value' ||
    column === 'Health') {
      if (score < 0 || score > 10) {
        console.log('error getting ' + column);
        return 0;
      }
    } else if (score < 0 || score > 5) {
      console.log('error getting ' + column);
      return 0;
    }
    return score;
  } catch (error) {
    console.log('error getting ' + column + ': ' + error.toString());
    return 0;
  }
};

export default offeringFromCsvRow;
