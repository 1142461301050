import React, { Component } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import CsvUploader from './CsvUploader';
import PropTypes from 'prop-types';
import AnimalListView from './AnimalListView';
import './admin.scss';
import Animal from './Animal';

class Admin extends Component {
  render () {
    return (
      <div id="admin" className="bb-page-wrapper">
        <div className="sidebar">
          <nav className="admin-sidebar">
            <h1>Admin</h1>
            <NavLink
              className="admin-menu-item"
              to={ `${this.props.match.url}/animals`}
              activeClassName='active'>
              animals
            </NavLink>
            <NavLink
              className="admin-menu-item"
              to={ `${this.props.match.url}/upload`}
              activeClassName='active'>
              uploader
            </NavLink>
          </nav>
        </div>
        <div className="content">
          <Switch>
            <Route exact path={`${this.props.match.url}/animals`} render={
              props => <AnimalListView { ...props } /> } />
            <Route exact
              path={`${this.props.match.url}/animals/:animalId`}
              render={
                props => <Animal { ...props } /> } />
            <Route path={`${this.props.match.url}/upload`} render={
              props => <CsvUploader { ...props } /> } />
          </Switch>
        </div>
      </div>
    );
  }
}

Admin.propTypes = {
  match: PropTypes.object
};

export default Admin;
