import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilterOperator,
  getFilterAttribute,
  getFilterValue } from '../../filterSelectors';
import {
  setFilterValue,
  setFilterAttribute,
  setFilterOperator
} from '../../actions';
import DropDownSelect from '../../../util/form/components/DropDownSelect';
import filterFunctions from '../util/filterFunctions';
import './admin-search-box.scss';
import SearchIcon from '../../../util/icons/components/SearchIcon';
import PropTypes from 'prop-types';

const getDataTypes = attributes => {
  const dataTypes = {};
  attributes.forEach(attribute => {
    dataTypes[attribute.attribute] = attribute.dataType;
  });
  return dataTypes;
};

const AdminSearchBox = ({ attributes }) => {
  const [ advancedSearch, setAdvancedSearch ] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const filterValue = useSelector(getFilterValue);
  const filterOperator = useSelector(getFilterOperator);
  const filterAttribute = useSelector(getFilterAttribute);
  const handleFilterValueChange = () => {
    const value = inputRef.current.value;
    dispatch(setFilterValue(value));
  };
  const attributeOptions = attributes.map(attribute => ({
    name: attribute.name,
    value: attribute.attribute,
    selected: attribute.attribute === filterAttribute
  }));
  const dataTypes = getDataTypes(attributes);
  const operatorOptions = Object.values(filterFunctions).filter(obj => {
    return obj.dataType === dataTypes[filterAttribute];
  }).map(obj => ({
    name: obj.symbol,
    value: obj.id,
    selected: filterOperator === obj.id
  }));
  useEffect(() => {
    dispatch(setFilterAttribute(null));
    dispatch(setFilterOperator(null));
    dispatch(setFilterValue(''));
  }, []);
  const handleToggleButtonClick = () => {
    dispatch(setFilterValue(''));
    if (advancedSearch) {
      dispatch(setFilterAttribute(null));
      dispatch(setFilterOperator(null));
      setAdvancedSearch(false);
    } else {
      setAdvancedSearch(true);
      dispatch(setFilterAttribute('name'));
      dispatch(setFilterOperator('contains'));
    }
  };
  const className = advancedSearch ? 'admin-search-box advanced' :
    'admin-search-box simple';
  return (
    <div className={ className }>
      <DropDownSelect options={ attributeOptions }
        className="attribute-select"
        testId="attribute-select"
        onChange={ val => dispatch(setFilterAttribute(val))} />
      <DropDownSelect options={ operatorOptions }
        className="operator-select"
        testId="operator-select"
        onChange={ val => dispatch(setFilterOperator(val))} />
      <div className="search-box-wrapper">
        <input className="search"
          data-cy="filter-input-box"
          ref={ inputRef }
          value={ filterValue || ''}
          onChange={ handleFilterValueChange } />
        <SearchIcon />
      </div>
      <div className='advanced-search-button'>
        <div className="text"
          data-cy="toggle-advanced-search-button"
          onClick={ handleToggleButtonClick }>
          { advancedSearch ? 'Simple Search' : 'Advanced Search' }
        </div>
      </div>
    </div>
  );
};

AdminSearchBox.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dataType: PropTypes.oneOf([
        'number', 'string', 'string-array'
      ])
    })
  )
};

export default AdminSearchBox;
