import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import GoodBeefShield from '../../util/icons/components/GoodBeefShield';
import QRCode from 'qrcode.react';
import './admin-animal-list-item.scss';
import moment from 'moment';
import getCreationTimeFromMongoId from '../util/getCreationTimeFromMongoId';
import { getScoreDefinitions } from '../scoreDefinitions/reducer';
import { calculateScore } from '../scoreDefinitions/util';
import { isAdmin, getOrgIds } from '../../util/jwt';

const AdminAnimalListItem = ({ animal, match }) => {
  const scoreDefinitions = useSelector(getScoreDefinitions);
  const domain = window.location.host;
  const protocol = window.location.protocol;
  const link = `${protocol}//${domain}/animal/${animal.id}`;
  let updateInfo = null;
  if (animal.lastUpdate) {
    const update = animal.lastUpdate;
    updateInfo = (
      <span className="update-info">
        { `Last changed ${moment(update.dateTime).fromNow()} 
          by ${update.userName || 'unknown'}` }
      </span>
    );
  }
  const creationString = getCreationTimeFromMongoId(animal.id);
  let viewCount = animal.views ? (
    <span className="view-count">
      { animal.views.toLocaleString() + ' views' }
    </span>
  ) : null;
  const statusClass = animal.liveOnGBI ? 'status-icon live' : 'status-icon';
  const viewNote = animal.liveOnGBI ? null : (
    <span className="demo-only-text">(demo only)</span>
  );
  const gbiShieldScore = animal.gbiScore ||
    calculateScore(animal, scoreDefinitions);
  const org = animal.org && (isAdmin() || getOrgIds().length > 1) ? (
    <span className="org">{ animal.org }</span>
  ) : null;
  return (
    <div
      data-cy="animal-list-view-item"
      className="admin-animal-list-item">
      <div className="block name-block">
        <div className="status-wrapper">
          <div className={ statusClass }></div>
          { updateInfo }
        </div>
        <Link to={ `${match.url}/${animal.id}`}>
          <h3 className="name"
            data-cy={ `animal-name-${animal.id}`}>{ animal.name }</h3>
        </Link>
        <Link to={ `${match.url}/${animal.id}`}>
          <h5 className="tag">
            <span>{ animal.tag}</span>
            { org }
          </h5>
        </Link>
      </div>
      <div className="block shield-block">
        <GoodBeefShield score={ gbiShieldScore }
          id={ 'shield_' + animal.id }/>
      </div>
      <div className="block qr-block">
        <QRCode size={ 60 } value={ link } renderAs='svg'/>
        <div className="info">
          <div className="top">
            { viewCount }
            <div className="creationDate">{ creationString }</div>
          </div>
          <div>
            <a href={ link }
              target="_blank"
              rel="noopener noreferrer"
              className="view-link">View</a>
            { viewNote }
          </div>
        </div>
      </div>
      <div className="block edit-button-block">
        <Link to={ `${match.url}/${animal.id}`}
          data-cy={ `edit-animal-${animal.id}`}
          className="edit-link">
            Edit
        </Link>
      </div>
    </div>
  );
};

AdminAnimalListItem.propTypes = {
  animal: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  scoreDefinitions: PropTypes.object
};

export default AdminAnimalListItem;
