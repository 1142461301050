import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const DropDownIcon = ({ onClick, className }) => (
  <svg onClick={ onClick } className={ className ? 'drop-down-icon ' + className : 'drop-down-icon' } viewBox="0 0 99.15 61.77">
    <path d="M50,62.39a5.24,5.24,0,0,1-4-1.81L1.7,9.26A5.23,5.23,0,1,1,9.61,2.42L50,49.16,90.39,2.42A5.23,5.23,0,1,1,98.3,9.26L54,60.58A5.21,5.21,0,0,1,50,62.39Z" transform="translate(-0.42 -0.61)"/>
  </svg>
);

DropDownIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default DropDownIcon;
