import React from 'react';
import TickIcon from '../util/icons/components/TickIcon';
import PropTypes from 'prop-types';
import './tick-box-field.scss';

const TickBoxField = ({ title, isOn, toggle, error, testId }) => {
  return (
    <div className="tick-box-form-field">
      <label className='form-label'>
        { title }
      </label>
      <div data-cy={ testId }
        className={ isOn ? 'tick-box on' : 'tick-box off' }
        onClick={ toggle }>
        <TickIcon />
      </div>
      <div className="field-error-text">{ error }</div>
    </div>
  );
};

TickBoxField.propTypes = {
  title: PropTypes.string,
  isOn: PropTypes.bool,
  toggle: PropTypes.func,
  error: PropTypes.string,
  testId: PropTypes.string
};

export default TickBoxField;
