import React, { useState, useRef } from 'react';
import AdminAPI from '../../../admin/AdminAPI';
import PropTypes from 'prop-types';
import documentDefinitions from '../../../cows/documentDefinitions';
import uuid from 'uuid/v4';
import './document-upload.scss';
import { captureException } from '../../../util/logger';
import Modal from '../../../main/components/Modal';
import ProgressBar from '../../../util/figures/ProgressBar';
import DropDownSelect from '../../../util/form/components/DropDownSelect';
import GBIButton from '../../../util/buttons/components/GBIButton';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';

const DocumentUpload = ({
  ownerId, uploaded, farmLevelOnly, removeFunc
}) => {
  const fileInputRef = useRef();
  const elementRef = useRef(uuid());
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [src, setSrc] = useState(null);
  const [progress, setProgress] = useState(0);
  const [documentType, setDocumentType] = useState(null);
  const [keyFact, setKeyFact] = useState(null);
  const [showDocTypeModal, setShowDocTypeModal] = useState(false);
  const onProgress = event => {
    if (event.lengthComputable) {
      const progress = event.loaded / event.total;
      setProgress(progress);
    }
  };
  const onChange = event => {
    const name = event.target.files[0].name;
    setName(name);
    setShowDocTypeModal(true);
    AdminAPI.saveFile(
      ownerId, name, event.target.files[0], onProgress
    ).then(response => {
      const data = response.data;
      setSrc(`https://${data.bucket}/${data.name}`);
      // setShowingFileTypeOptions(true);
    }).catch(error => {
      captureException(error);
      setError('File uploaded failed. Network error');
    });
  };
  const save = () => {
    if (!documentType) {
      setError('Please select a document type');
    } else if (src) {
      uploaded(src, documentType, keyFact);
      reset();
    }
  };
  const reset = () => {
    setError(null);
    if (src && progress === 1 && removeFunc) {
      removeFunc(src);
    }
    setSrc(null);
    setName(null);
    setKeyFact(null);
    setDocumentType(null);
    setShowDocTypeModal(false);
    fileInputRef.current.value = null;
  };
  let docTypeModal = null;
  if (showDocTypeModal) {
    const docTypeOptions = Object.keys(documentDefinitions).filter(key => {
      if (documentDefinitions[key].legacy) return false;
      if (farmLevelOnly && !documentDefinitions[key].farmLevel) return false;
      return true;
    }).map(key => ({
      name: documentDefinitions[key].adminName || documentDefinitions[key].name,
      value: key,
      selected: documentType === key
    }));
    const saveButtonClass = !error && src && documentType ? '' : 'disabled';
    const errorText = error ? (
      <div className="error">{ error }</div>
    ) : null;
    const title = src && progress === 1 ? (
      <a className="file"
        target="_blank"
        data-cy="uploaded-file-link"
        rel="noopener noreferrer"
        href={ src }>
        Uploaded
        <DownloadIcon />
      </a>
    ) : 'Uploading...';
    docTypeModal = (
      <Modal pos={ window.scrollY }
        close={ reset }
        contentClass="document-upload-modal">
        <h2 className="mini-title">
          { title }
        </h2>
        <h1 className="main-title">{ name }</h1>
        <div className="progress-wrapper">
          <ProgressBar percentage={ progress * 100} />
        </div>
        {/* <div className="progress-percentage">
          { progress * 100} %
        </div> */}
        <div className="dropdowns">
          <DropDownSelect onChange={ docType => setDocumentType(docType)}
            options= { docTypeOptions }
            placeholder="select document category"
            testId="document-type" />
        </div>
        { errorText }
        <div className="button-row">
          <GBIButton label="cancel"
            testId="document-cancel-button"
            onClick={ reset }/>
          <GBIButton label="confirm"
            testId="document-save-button"
            onClick={ save }
            className={ saveButtonClass }/>
        </div>
      </Modal>
    );
  }
  return (
    <div className="document-upload-widget">
      <label htmlFor={ elementRef.current }>
        <GBIButton label="Upload Document +"/>
      </label>
      <input
        id={ elementRef.current }
        ref={fileInputRef}
        className="file-input"
        type="file"
        multiple
        data-cy="document-upload-input"
        onChange={onChange} />
      { docTypeModal }
    </div>
  );
};

DocumentUpload.propTypes = {
  ownerId: PropTypes.string.isRequired,
  uploaded: PropTypes.func.isRequired,
  removeFunc: PropTypes.func,
  farmLevelOnly: PropTypes.bool
};

export default DocumentUpload;
