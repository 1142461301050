import React from 'react';
import ChevronIcon from '../icons/components/ChevronIcon';
import './pagination.scss';
import PropTypes from 'prop-types';

const Pagination = ({ toPage, total, current }) => {
  if (total === 1) return null;
  // const first = () => toPage(1);
  const back = () => {
    if (current > 1) toPage(current - 1);
  };
  const forward = () => {
    if (current < total) toPage(current + 1);
  };
  const last = () => toPage(total);
  // const firstButton = (
  //   <li
  //     onClick={first}
  //     className={(current === 1) ? 'first disabled' : 'first'}>
  //     <ChevronIcon
  //       className='pagination-arrow-left'/>
  //     <ChevronIcon
  //       className='pagination-arrow-left' />
  //   </li>
  // );
  const backButton = (
    <li
      onClick={back}
      className={(current === 1) ? 'back disabled' : 'back'}>
      <ChevronIcon
        className='pagination-arrow-left'/>
    </li>
  );
  const forwardButton = (
    <li
      onClick={forward}
      className={(current === total) ? 'forward disabled' : 'forward'}>
      <ChevronIcon
        className='pagination-arrow-right'/>
    </li>
  );
  // const lastButton = (
  //   <li
  //     onClick={last}
  //     className={(current === total) ? 'last disabled' : 'last'}>
  //     <ChevronIcon
  //       className='pagination-arrow-right'/>
  //     <ChevronIcon
  //       className='pagination-arrow-right'/>
  //   </li>
  // );
  const numbers = [];
  if (current - 3 > 0) {
    numbers.push((
      <li
        key={1}
        onClick={() => toPage(1)}
        className='pagination-page'>
        { '1' }
      </li>
    ));
    if (current - 4 > 0) {
      numbers.push((
        <li
          key={ 'ellipsis-start' }
          className='pagination-page'>
          { '...' }
        </li>
      ));
    }
  }
  if (current - 2 > 0) {
    numbers.push((
      <li
        key={current - 2}
        onClick={() => toPage(current - 2)}
        className='pagination-page'>
        {current - 2}
      </li>
    ));
  } else {
    numbers.push(
      <li key={current - 2} className='pagination-page blank'></li>
    );
  }
  if (current - 1 > 0) {
    numbers.push((
      <li
        key={current - 1}
        onClick={() => toPage(current - 1)}
        className='pagination-page'>
        {current - 1}
      </li>
    ));
  } else {
    numbers.push(
      <li key={current - 1} className='pagination-page blank'></li>
    );
  }
  numbers.push((
    <li
      key={current}
      className='pagination-page active'>
      {current}
    </li>
  ));
  if (current + 1 <= total) {
    numbers.push((
      <li
        key={current + 1}
        onClick={() => toPage(current + 1)}
        className='pagination-page'>
        {current + 1}
      </li>
    ));
  } else {
    numbers.push(
      <li key={current + 1} className='pagination-page blank'></li>
    );
  }
  if (current + 2 <= total) {
    numbers.push((
      <li
        key={current + 2}
        onClick={() => toPage(current + 2)}
        className='pagination-page'>
        {current + 2}
      </li>
    ));
  } else {
    numbers.push(
      <li key={current + 2} className='pagination-page blank'></li>
    );
  }
  if (current + 3 < total) {
    numbers.push((
      <li
        key={ 'ellipsis-end' }
        className='pagination-page'>
        { '...' }
      </li>
    ));
    numbers.push((
      <li
        key={ total }
        onClick={ last }
        className='pagination-page'>
        { total }
      </li>
    ));
  } else if (current + 2 < total) {
    numbers.push((
      <li
        key={ total }
        onClick={ last }
        className='pagination-page'>
        { total }
      </li>
    ));
  }
  const stringPage = current + '';
  let className = 'pagination';
  if (stringPage.length === 3) {
    className += ' three';
  } else if (stringPage.length === 4) {
    className += ' four';
  } else if (stringPage.length > 4) {
    className += ' loads';
  }
  return (
    <ul className={ className }>
      {/* { firstButton } */}
      { backButton }
      { numbers }
      { forwardButton }
      {/* { lastButton } */}
    </ul>
  );
};

Pagination.propTypes = {
  toPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired
};

export default Pagination;
