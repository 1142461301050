import documentDefinitions from '../../../cows/documentDefinitions';

const docArrayToObj = docArray => {
  const documents = docArray && Array.isArray(docArray) ? docArray : [];
  const obj = {};
  documents.forEach((doc, index) => {
    obj[doc.key] = {
      src: doc.src,
      position: doc.position || (index + 1),
      keyFact: doc.keyFact || documentDefinitions[doc.key].name
    };
  });
  return obj;
};

export default docArrayToObj;
