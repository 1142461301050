import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import GBIButton from '../../../util/buttons/components/GBIButton';
import { useSelector } from 'react-redux';
import { getOrgsObj } from '../../orgAdmin/reducer';
import Modal from '../../../main/components/Modal';
import documentDefinitions from '../../../cows/documentDefinitions';
import './farm-document-selector.scss';

const FarmDocumentSelector = ({ animal, add }) => {
  const [ showModal, setShowModal ] = useState(false);
  const [ key, setKey ] = useState(null);
  const [ src, setSrc ] = useState(null);
  const orgs = useSelector(getOrgsObj);
  if (
    !animal ||
    !animal.orgId ||
    !orgs[animal.orgId] ||
    !orgs[animal.orgId].documents) return null;
  const farm = orgs[animal.orgId];
  const docKeys = Object.keys(farm.documents).filter(key => {
    return !animal.documents || !animal.documents[key];
  });
  if (docKeys.length < 1) return null;
  const selectDocument = (key, src) => {
    setKey(key);
    setSrc(src);
  };
  const unselectDocument = () => {
    setKey(null);
    setSrc(null);
  };
  const save = () => {
    if (document && key && src) {
      add(key, src);
    }
    setKey(null);
    setSrc(null);
    setShowModal(false);
  };
  const closeModal = () => {
    setShowModal(false);
    setKey(null);
    setSrc(null);
  };
  let modal = null;
  if (showModal) {
    const farm = orgs[animal.orgId];
    const documents = docKeys.map(docKey => {
      let className = 'herd-level-document';
      let cancelFunc = () => {};
      if (src) {
        if (key === docKey) {
          className += ' selected';
          cancelFunc = event => {
            event.preventDefault();
            event.stopPropagation();
            unselectDocument();
          };
        } else {
          className += ' hidden';
        }
      }
      const document = farm.documents[docKey];
      return (
        <div key={ docKey }
          onClick={ () => selectDocument(docKey, document.src)}
          data-cy={ `herd-level-doc-${docKey}`}
          className={ className }>
          <div className="document-type">
            { documentDefinitions[docKey].name + ':' }
          </div>
          <div className="file-name">
            { /[^/]*$/.exec(document.src)[0] }
          </div>
          <div className="add" onClick={ cancelFunc }>
            +
          </div>
        </div>
      );
    });
    const saveButtonClass = src ? 'save-button' : 'save-button disabled';
    const title = src ? 'Confirm your selection' : 'Select a document';
    modal = (
      <Modal pos={ window.scrollY }
        contentClass="farm-document-selector"
        close={ closeModal }>
        <h2 className="mini-title">{ farm.name }</h2>
        <h1 className="main-title">{ title }</h1>
        <div className="documents">
          { documents }
        </div>
        <div className="button-row">
          <GBIButton label="cancel"
            className="cancel-button"
            testId="cancel-button"
            onClick={ () => setShowModal(false) } />
          <GBIButton label="confirm"
            className={ saveButtonClass }
            testId="document-save-button"
            onClick={ save } />
        </div>
      </Modal>
    );
  }
  return (
    <Fragment>
      <GBIButton label="Add Farm Document +"
        className="add-farm-doc-button"
        testId="add-farm-doc-button"
        onClick={ () => setShowModal(true) }/>
      { modal }
    </Fragment>
  );
};

FarmDocumentSelector.propTypes = {
  animal: PropTypes.object,
  add: PropTypes.func
};

export default FarmDocumentSelector;
