import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDownSelect from '../util/form/components/DropDownSelect';

class DropDownSelectField extends Component {
  getError () {
    return this.props.error ? this.props.error : null;
  }
  render () {
    let labelClass = 'form-label';
    if (this.getError()) labelClass += ' error';
    return (
      <div className="form-field-wrapper">
        <label className={ labelClass }>
          { this.props.name }
        </label>
        <DropDownSelect
          testId={ this.props.testId }
          onChange={ this.props.update }
          placeholder="select"
          options={ this.props.options } />
        <div className="field-error-text">{ this.getError() }</div>
      </div>
    );
  }
}

DropDownSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      selected: PropTypes.bool
    })
  ),
  error: PropTypes.string,
  update: PropTypes.func,
  testId: PropTypes.string
};

export default DropDownSelectField;
